import React from 'react';
import { Link } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { IoIosMore } from 'react-icons/io';

import { Stacked, LineChart, LinePriorityChart, Navbar, Sidebar } from '../components';
import { lineChartData, lineCustomSeries, linePriorityData, priorityOHDiseases, otherOHDiseases } from '../data/dummy';
import leftLogo from '../images/leftLogo.png';
import rightLogo from '../images/rightLogo.png';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { FiSettings } from 'react-icons/fi';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";



const Home = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();
  return (
    <div className='mt-1'>
    <div className='fixed right-4 bottom-4' style={{ zIndex: '1000' }}>
      <TooltipComponent content='Settings' position='Top'>
        <button
          type='button'
          onClick={() => setThemeSettings(true)}
          style={{ background: currentColor, borderRadius: '50%' }}
          className='text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray'
        >
          <FiSettings />
        </button>
      </TooltipComponent>
    </div>
    <div className='fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full '>
    {/* <Navbar /> */}
      </div>

      <div className='flex flex-wrap lg:flex-nowrap justify-center'>
      <img className='flex align-middle justify-center rounded-xl h-20 ml-2 mt-4 w-auto bg-white dark:bg-secondary-dark-bg' src={leftLogo}/> 
        <div className='bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-26 rounded-xl w-full lg:w-full p-6 pt-4 m-3 bg-no-repeat bg-center'>
            <div className='flex justify-center items-center'>
              <p className='text-2xl text-center font-semibold w-760 text-green-700'>One Health Surveillance Dashboard, Punjab</p>
            </div>
            <div>
              <p className='flex justify-center items-center ml-6 mr-6 text-base font-thin text-center text-black dark:text-white'>A digital platform for consolidation of information on One Health priority diseases and analysis/joint decision making.</p>
            </div>
          </div>
          <img className='flex align-middle rounded-xl justify-center h-20 mr-2 mt-4 w-auto bg-white dark:bg-secondary-dark-bg' src={rightLogo}/>
        </div>

      <div className='flex gap-1 flex-wrap justify-center'>
        <div className='bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-1 p-2 rounded-2xl md:w-auto'>
        {/* <div className='flex justify-between'>
          </div>           */}
          <div className='flex justify-between items-center gap-1 mt-4 ml-2'>
            <p className='text-xl font-semibold'>Quarterly Overview (OH Priority Diseases)</p>
          </div>
          <div className='mt-10 flex gap-2 flex-wrap justify-center'>
            <div className=' border-r-1 border-color m-1 pr-4'>
              <div className='mt-1'>
                <LinePriorityChart currentColor={currentColor} id='line-pcharts' height='380px' width='250px' data={linePriorityData}/>
              </div>
              <div className='mt-2'>
              </div>
            </div>
              <Stacked currentMode={currentMode} width='275px' height='380px' />
          </div>
          {/*
          <div className={'flex mt-5 justify-end items-center mr-8'}>
          <Link to={'/Maps'}>
                <button
                  type='button'
                  style={{ background: currentColor}}
                  className='text-base text-white pl-3 pr-3 pt-2 pb-2 hover:drop-shadow-xl hover:bg-light-gray rounded-md'>
                  Map Representation
                </button>
              </Link>
              </div>
              */}
        </div>        
        <div className='bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-4 pt-2 m-2 rounded-2xl h-auto'>

            <p className='text-base font-semibold pt-4 text-center'>Recent Cases</p>
          <div className='mt-4 w-auto md:w-auto h-auto'>
            {priorityOHDiseases.map((item) => (
              <div key={item.title} className='flex justify-between mt-4'>
                <Link to={item.to}>
                <div className='flex gap-4'>
                  <button
                    type='button'
                    style={{
                      color: item.iconColor,
                      backgroundColor: item.iconBg,
                    }}
                    className='text-xs rounded-lg p-4 hover:drop-shadow-xl'
                  >
                    {item.icon}
                  </button>
                  <div>
                    <p className='text-sm font-semibold'>{item.title}</p>
                    <p className='text-xs text-gray-400'>{item.desc}</p>
                  </div>
                </div>
                </Link>
                <p className={`text-${item.pcColor}`}>{item.amount}</p>
              </div>
            ))}
          </div>
          <div className='flex justify-between items-center gap-1 mt-5'>       
          <button
                type='button'
                onClick={(e) => {
                e.preventDefault();
                window.location.href='http://pakonehealth.org/ebs'} }
                style={{ background: currentColor}}
                className='text-base text-white pl-3 pr-3 pt-2 pb-2 hover:drop-shadow-xl hover:bg-light-gray rounded-md'>
                Event Based Surveillance
          </button>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap justify-center'>
        <div className='md:w-360 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 pt-4 m-3'>
          <div className='flex justify-between'>
            <p className='text-base font-semibold text-center'>Other OH Diseases</p>
            {/*}
            <button type='button' className='text-xl font-semibold text-gray-500'>
              <IoIosMore />
            </button>
                */}
          </div>

          <div className='mt-4 w-auto md:w-auto'>
            {otherOHDiseases.map((item) => (
              <div key={item.title} className='flex justify-between mt-2'>
                <Link to={item.to}>
                <div className='flex gap-2 mr-4'>
                  <button
                    type='button'
                    style={{ background: item.iconBg }}
                    className='text-xs rounded-lg p-4 hover:drop-shadow-xl'
                  >
                    {item.icon}
                  </button>
                  <div>
                    <p className='text-sm font-semibold'>{item.title}</p>
                    <p className='text-xs text-gray-400'>{item.desc}</p>
                  </div>

                </div>
                </Link>
                <p className={`text-${item.pcColor}`}>{item.amount}</p>
              </div>
            ))}
          </div>

        </div>
      <div className='flex gap-10 m-4 flex-wrap justify-center'>

        <div className='bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 pt-4 m-3 rounded-2xl w-96 md:w-800'>
          <div className='flex justify-between items-center gap-2 mb-10'>
            <p className='text-xl font-semibold'>Quarterly Overview (Other Diseases)</p>
          </div>
          <div className='md:w-full overflow-auto'>
            <LineChart 
            currentColor={currentColor} 
            id='line-charts' 
            width='250px' 
            height='80px' 
            data={lineChartData}/>
            </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Home;