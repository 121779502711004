import React , {Component} from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  Edit,
  Inject,
} from '@syncfusion/ej2-react-grids';
import { zoonoticData, DHIS2Grid, DiseasesXAxis, DiseasesYAxis, LinePrimaryXAxis } from '../data/dummy';
import { Header, Button, Navbar, LineChart } from '../components';
import { Link } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { 
  Category, 
  ChartComponent, 
  LineSeries, 
  SeriesCollectionDirective, 
  SeriesDirective,
  Legend,
  DataLabel,
  ColumnSeries
 } from '@syncfusion/ej2-react-charts';
import DiseaseLineChart from '../components/Charts/DiseaseLineChart';
import axios from 'axios';
import Base64 from 'base-64';
import { dhis2dec, dhis2nov, dhis2oct } from '../data/dhis2punjab'
import { dogBiteData, entriesGrid } from '../data/dummy';
import dogBitesData from '../data/dogbites 22.json';

export default class DHIS2Punjab extends Component{
  constructor(props){
    super(props);
    this.state = {dhis2punjab :[]};
  }
  Tabs = ({ color }) => {
    const [openTab, setOpenTab] = React.useState(1);
    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full">
            <ul
              className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Data Table
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                   Charts
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  {/*
                  Tab 1
                  */}
                      <GridComponent 
                        id='dogbite'
                        dataSource={dogBitesData}
                        /*dataSource={this.state.dogbites}*/
                        allowFiltering={true}
                        allowPaging={true}
                        defaultValue={"Loading Data"}
                        filterSettings={this.filterSettings}
                        
                        width='auto'>
                        
                          <ColumnsDirective>
                            {entriesGrid.map((item, index) => (
                            /* eslint-disable react/jsx-props-no-spreading */
                            <ColumnDirective key={index} {... item}/>
                          ))}
                          <ColumnDirective field='Visit Date' headerText='Date' textAlign='left' width='40' format='yMd' />
                          <Inject
                            services={[
                            Filter,
                            Page,
                            Search,
                          ]}
                        />
                          
                          </ColumnsDirective>
                        </GridComponent>
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                    {/*
                    Tab 2
                    */}
                        <ChartComponent
                        title='Dog Bites'
                        primaryXAxis={{valueType:"Category", title:"Months"}}
                        primaryYAxis={{title:"Cases"}} legendSettings={{visible:true}}
                        tooltip={{enable:true}}
                        >
                          <Inject services={[ColumnSeries, LineSeries, Category, Legend, DataLabel]}/>
                          <SeriesCollectionDirective>
                            <SeriesDirective
                            type='Column'
                            dataSource={dogBiteData}
                            xName='month'
                            yName='count'
                            name='Diseases'
                            marker={{dataLabel:{visible:true}, visible:true}}>

                            </SeriesDirective>
                          </SeriesCollectionDirective>
                        </ChartComponent>
                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=03b77dcd-fc42-4585-ac65-1f723c6fb1e6&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=fe20c65f-2079-455a-8d6b-090af987aad2&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=4541e9bc-d1df-4b12-b993-3a8e0f147a84&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  componentDidMount(){
    const user = ['pspu_provincial', 'dhis2@PSPU76'];
    const tok = "pspu_provincial:dhis2@PSPU76";
    const hash = Base64.encode(tok);
    const Basic = "Basic " + hash;
    const token = '';
    axios.get('http://116.58.20.67:8080/api/32/analytics.json?dimension=dx:E2MQ8ypL562;HWGyRvqrUEi;aM3Na2jQTts;EU6zq5FygUi;AdIWS3Qd4Lx;nvmd6bKU7Mj;UhColMlS7sk;jdogBqT66LU;Af99m9CJdpH;hvsWLHwNqSJ&dimension=ou:SbuDOngzpe5;LEVEL-rgwzuMloh57&showHierarchy=false&hierarchyMeta=false&includeMetadataDetails=true&includeNumDen=false&skipRounding=false&completedOnly=true&outputIdScheme=Name&filter=pe:202210',
    {
      headers : { 'Authorization' : Basic }
    })
    .then(response => {
      this.setState({dhis2punjab: response.data});
      console.log(response.data);
      console.log(response.headers['Authorization']);

    })
    .catch((error) => {
        console.log(error);
        console.log("Not receiving data");
        console.log(Basic)
    })
  }
  render() {
    return (    
    <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
    <Header category='Data Source' title='DHIS - 2 (Punjab)' />
    <div className='mb-2'>
    <Link to ='/DHIS2Punjab/january'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='January 22'
          bgHoverColor='gray-500'
        />
      </Link>
    <Link to ='/DHIS2Punjab/February'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='February 22'
          bgHoverColor='gray-500'
        />
      </Link>
    <Link to ='/DHIS2Punjab/March'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='March 22'
          bgHoverColor='gray-500'
        />
      </Link>
      <Link to ='/DHIS2Punjab/april'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='April 22'
          bgHoverColor='gray-500'
        />
      </Link>
    <Link to ='/DHIS2Punjab/may'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='May 22'
          bgHoverColor='gray-500'
        />
      </Link>
      <Link to ='/DHIS2Punjab/june'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='June 22'
          bgHoverColor='gray-500'
        />
      </Link>
        <Link to ='/DHIS2Punjab/july'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='July 22'
          bgHoverColor='gray-500'
        />
      </Link>
      <Link to ='/DHIS2Punjab/august'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='August 22'
          bgHoverColor='gray-500'
        />
      </Link>
      <Link to ='/DHIS2Punjab/september'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='September 22'
          bgHoverColor='gray-500'
        />
      </Link>
      <Link to ='/DHIS2Punjab/november'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='November 22'
          bgHoverColor='gray-500'
        />
      </Link>
      <Link to ='/DHIS2Punjab/december'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='December 22'
          bgHoverColor='gray-500'
        />
      </Link>
    </div>
    <iframe width="800" height="600" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=15490dab-62ca-4c3e-9638-b06f78b8db36&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
    {/*
    <GridComponent
        id='gridcomp'
        dataSource={dhis2dec}
        allowFiltering={true}
        filterSettings={filterSettings}
        toolbar={['Search']}
        width='auto'
      >
      <ColumnsDirective>
        {DHIS2Grid.map((item, index) => (
        */
          /* eslint-disable react/jsx-props-no-spreading */}
              {/*
          <ColumnDirective key={index} {...item} />
        ))}
      </ColumnsDirective>
      <Inject
        services={[
          Search,
          Toolbar,
          Resize,
          ContextMenu,
          Filter,
          Page,
        ]}
      />
    </GridComponent>

    <this.Tabs color="teal"/>
    */}
    </div>
    
    )
  }
}