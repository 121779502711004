import React from 'react'
import { pieChartData } from '../../data/dummy';
import { ChartsHeader, Navbar, Pie as PieChart } from '../../components';


const Pie = () => {
  return (  
  <div className='m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl'>
    <div className='fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full '>
    <Navbar />
      </div>
    <ChartsHeader category='Pie' title='Diseases Trends in Pakistan (2022)' />
    <div className='w-full'>
      <PieChart id='chart-pie' data={pieChartData} legendVisiblity height='full' />
    </div>
  </div>
  )
}

export default Pie