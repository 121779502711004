import React, { useEffect } from 'react'
import './App.css';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar, Sidebar, ThemeSettings } from "./components";
import { useStateContext } from './contexts/ContextProvider';

//Pages
import Home from './pages/Home';
import Zoonotic from './pages/Zoonotic';
import VectorBorne from './pages/VectorBorne';
import WaterBorne from './pages/WaterBorne';
import Pandemic from './pages/Pandemic';
import GeoTags from './pages/GeoTags';
import Definitions from './pages/Definitions';
import DataSources from './pages/DataSources';

//Charts etc
import Area from './pages/Charts/Area';
import Bar from './pages/Charts/Bar';
import Pie from './pages/Charts/Pie';
import MapPage from './pages/MapPage';
import UserList from './components/UserList';
import Entries from './components/Entries';

//Diseases
//Zoonotic
import Anthraxes from './diseases/Zoonotic/Anthraxes';
import BovineTuberculoses from './diseases/Zoonotic/BovineTuberculoses';
import Brucelloses from './diseases/Zoonotic/Brucelloses';
import Rabies from './diseases/Zoonotic/Rabies';
import Salmonelloses from './diseases/Zoonotic/Salmonelloses';
import DogBites from './diseases/Zoonotic/DogBites';

//VectorBorne
import CCHFs from './diseases/VectorBorne/CCHFs';
import Chikungunyas from './diseases/VectorBorne/Chikungunyas';
import Dengues from './diseases/VectorBorne/Dengues';
import Leishmaniases from './diseases/VectorBorne/Leishmaniases';
import Malarias from './diseases/VectorBorne/Malarias';
import Zikas from './diseases/VectorBorne/Zikas';

//Waterborne
import Amebiases from './diseases/WaterBorne/Amebiases';
import Choleras from './diseases/WaterBorne/Choleras';
import Hepatitises from './diseases/WaterBorne/Hepatitises';
import Typhoids from './diseases/WaterBorne/Typhoids';

//Pandemics
import AvianInfluenzas from './diseases/Pandemic/AvianInfluenzas';
import Coronas from './diseases/Pandemic/Coronas';
import Login from './pages/Login';

//Data Sources
import DHIS2Punjab from './pages/DHIS2Punjab';


import DHIS2Jan from './data/dhis2punjab/JanuaryJS';
import DHIS2Feb from './data/dhis2punjab/FebruaryJS';
import DHIS2Mar from './data/dhis2punjab/MarchJS';
import DHIS2Apr from './data/dhis2punjab/AprilJS';
import DHIS2May from './data/dhis2punjab/MayJS';
import DHIS2Jun from './data/dhis2punjab/JuneJS';
import DHIS2Jul from './data/dhis2punjab/JulyJS';
import DHIS2Aug from './data/dhis2punjab/AugustJS';
import DHIS2Sep from './data/dhis2punjab/SeptemberJS';
import DHIS2Oct from './data/dhis2punjab/OctoberJS';
import DHIS2Nov from './data/dhis2punjab/NovemberJS';
import DHIS2Dec from './data/dhis2punjab/DecemberJS';
//import Test from './test';


const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
    <div className= 'flex relative dark:bg-main-dark-bg'>
    
    <div className='fixed right-4 bottom-4' style={{ zIndex: '1000' }}>
            <TooltipComponent content='Settings' position='Top'>
              <button
                type='button'
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: '50%' }}
                className='text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray'
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          
          {activeMenu ? (
            <div className='w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white '>
              <Sidebar />
            </div>
          ) : (
            <div className='w-0 dark:bg-secondary-dark-bg'>
              <Sidebar />
            </div>
          )}
          
          <div
            className={
              activeMenu
                ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            }
          >
        <div>
          {themeSettings && <ThemeSettings />}
          <Routes>
            {/* Dashboard  */}
            <Route path='/' element={<Home />} />
            <Route path='/Login' element={<Login />} />
            <Route path='/Home' element={<Home />} />

            {/* Pages  */}
            <Route path='/Zoonotic' element={<Zoonotic />} />
            <Route path='/Vectorborne' element={<VectorBorne />} />
            <Route path='/Waterborne' element={<WaterBorne />} />
            <Route path='/Pandemic' element={<Pandemic />} />
            <Route path='/GeoTags' element={<GeoTags />} />
            <Route path='/DataSources' element={<DataSources />} />
            <Route path='/Definitions' element={<Definitions />} />
            <Route path='/Area' element={<Area />} />
            <Route path='/Bar' element={<Bar />} />
            <Route path='/Pie' element={<Pie />} />
            <Route path='/Maps' element={<MapPage />} />
            <Route path='/UserList' element={<UserList />}/>
            <Route path='/Entries' element={<Entries />}/>

            {/* Diseases */}
            <Route path='/Zoonotic/dogbites' element={<DogBites/>}/>
            <Route path='/Zoonotic/anthraxes' element={<Anthraxes/>}/>
            <Route path='/Zoonotic/brucelloses' element={<Brucelloses/>}/>
            <Route path='/Zoonotic/bovinetuberculoses' element={<BovineTuberculoses/>}/>
            <Route path='/Zoonotic/rabies' element={<Rabies/>}/>
            <Route path='/Zoonotic/salmonelloses' element={<Salmonelloses/>}/>
            <Route path='/vectorborne/cchfs' element={<CCHFs/>}/>
            <Route path='/vectorborne/chikungunyas' element={<Chikungunyas/>}/>
            <Route path='/vectorborne/dengues' element={<Dengues/>}/>
            <Route path='/vectorborne/leishmaniases' element={<Leishmaniases/>}/>
            <Route path='/vectorborne/malarias' element={<Malarias/>}/>
            <Route path='/vectorborne/zikas' element={<Zikas/>}/>
            <Route path='/waterborne/amebiases' element={<Amebiases/>}/>
            <Route path='/waterborne/choleras' element={<Choleras/>}/>
            <Route path='/waterborne/hepatitises' element={<Hepatitises/>}/>
            <Route path='/waterborne/typhoids' element={<Typhoids/>}/>
            <Route path='/pandemic/avianinfluenzas' element={<AvianInfluenzas/>}/>
            <Route path='/pandemic/coronas' element={<Coronas/>}/>            
            <Route path='/DHIS2Punjab' element={<DHIS2Punjab/>}/>         

            <Route path='/DHIS2Punjab/january' element={<DHIS2Jan/>}/>
            <Route path='/DHIS2Punjab/february' element={<DHIS2Feb/>}/>
            <Route path='/DHIS2Punjab/march' element={<DHIS2Mar/>}/>
            <Route path='/DHIS2Punjab/april' element={<DHIS2Apr/>}/>
            <Route path='/DHIS2Punjab/may' element={<DHIS2May/>}/>
            <Route path='/DHIS2Punjab/june' element={<DHIS2Jun/>}/>
            <Route path='/DHIS2Punjab/july' element={<DHIS2Jul/>}/>
            <Route path='/DHIS2Punjab/august' element={<DHIS2Aug/>}/>
            <Route path='/DHIS2Punjab/september' element={<DHIS2Sep/>}/>
            <Route path='/DHIS2Punjab/october' element={<DHIS2Oct/>}/>
            <Route path='/DHIS2Punjab/november' element={<DHIS2Nov/>}/>
            <Route path='/DHIS2Punjab/december' element={<DHIS2Dec/>}/>
            {/*
            <Route path='/test' element={<Test/>}/>
          */}
          </Routes>
        </div>
        </div>
        </div>
  </BrowserRouter>
  </div>
  )
}

export default App