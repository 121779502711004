import React, { useMemo } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { GoogleMap, Marker } from "react-google-maps";
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import withGoogleMap from 'react-google-maps/lib/withGoogleMap';
import mapStyles from '../../components/mapStyles';
import mapStylesSilver from '../../components/mapStylesSilver';
import disData from '../../data/dogbites 22.json';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import InfoWindow from 'react-google-maps/lib/components/InfoWindow';

//var LatLngLiteral = google.maps.LatLngLiteral; 

const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight:2,
  clickable:false,
  draggable:false,
  editable:false,
  visible:true,
};

const criticalOptions = {
  ...defaultOptions,
  zIndex:2,
  fillOpacity:0.05,
  strokeColour:'#FF5252',
  fillColour:'#FF5252',
};

function Map(){
  return (
  <GoogleMap
  defaultZoom={10}
  defaultCenter={{lat: 30.123456,lng: 72.456789}}
  defaultOptions={{ styles: mapStylesSilver }}
  clickableIcons={true}
  >
    
    <Marker
    key={'001'}
    position={{
      lat:30.125896,
      lng:72.785462
    }}/>
    <Marker
    key={'002'}
    position={{
      lat:30.123456,
      lng:72.456789
    }}
   /*onClick={() => {
      (
      <InfoWindow
      position={{
        lat:33.677633270905325,
        lng:72.72851572509767 }}
        >
        <div>
          Disease Detail
        </div>
      </InfoWindow>
  )}
    
    />
    <Marker
    key={'003'}
    position={{
      lat:30.521463,
      lng:72.321789
    }}*/
    />
    {
      disData.map((entry) => 
      <Marker
      key={entry._id}
      position={{
        lat:entry.Latitude,
        lng:entry.Longitude
      }}
      />
      )
    }
  </GoogleMap>
  );
}
const WrappedMap = withScriptjs(withGoogleMap(Map))

export default function MapsAnthrax() {
  {disData.map((entry) => 
  console.log(entry._id)
    )}
  const center = useMemo(
    () => ({lat:33.6753, lng:72.69796}), 
    []);

  const options = useMemo(
    () => ({
      disableDefaultUI:true,
      clickableIcons:false,
    }),
    []);

  return (
    <div style={{width:"100%", height:"100vh"}}>
    <div className='container m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl w-2/3 h-2/3'>
    <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${
          process.env.REACT_APP_GOOGLE_KEY
        }`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      </div>
    </div>
  )
}