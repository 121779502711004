import { ColumnsDirective, ColumnDirective , GridComponent } from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
import React, {Component} from 'react';
import axios from 'axios';
import { entriesGrid } from '../data/dummy';

const Entry = props => (
  <tr>
    <td>{props.entry.username}</td>
    <td>{props.entry.description}</td>
    <td>{props.entry.duration}</td>
    <td>{props.entry.date.substring(0,10)}</td>
  </tr>
)

export default class Entries extends Component {
  constructor(props)
  {
    super(props);
    this.state = {entries :[]};
  }

  componentDidMount(){
    axios.get('http://localhost:5000/entries')
    .then(response => {
      this.setState({entries: response.data})
    })
    .catch((error) => {
      console.log(error);
      console.log("Not receiving data");
    })
  }

  entriesList(){
    return this.state.entries.map(currentEntry => {
      return <Entry entries={currentEntry} />;
    })
  }
  filterSettings = { type: 'Excel' };
  fields = { text: 'text', value: 'value' };
  format = { type: 'datetime', format: 'M/d/y hh:mm a' };
  render() {
    return (
        <div>
        <h3>Entries</h3>
        <GridComponent 
        id='entries1'
        dataSource={this.state.entries}
        allowFiltering={true}
        filterSettings={this.filterSettings}
        width='auto'>
          <ColumnsDirective>         
            <ColumnDirective field='disease' headerText='Disease' width='100' textAlign='left'></ColumnDirective>
            <ColumnDirective field='hospitalDistrict' headerText='Hospital District' width='100' textAlign='left'></ColumnDirective>
            <ColumnDirective field='facilityType' headerText='Facility Type' width='60' format={this.format} textAlign='left'/>
            <ColumnDirective field='date' headerText='Date' width='100' format='C2' textAlign='left'/>
            <ColumnDirective field='age' headerText='Age' width='40'></ColumnDirective>
            <ColumnDirective field='gender' headerText='Gender' width='40'></ColumnDirective>          
          </ColumnsDirective>
        </GridComponent>
        <div className='mt-10'>

        </div>
        <GridComponent 
        id='entries'
        dataSource={this.state.entries}
        allowFiltering={true}
        filterSettings={this.filterSettings}
        width='auto'>
          <ColumnsDirective>
            {entriesGrid.map((item, index) => (
            /* eslint-disable react/jsx-props-no-spreading */
            <ColumnDirective key={index} {... item}/>
          ))}
          
          </ColumnsDirective>
        </GridComponent>
        </div>
    )
  }
}