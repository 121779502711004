import React from 'react';
import { AiOutlineAreaChart, AiOutlineBarChart } from 'react-icons/ai';
import { FiPieChart, FiBarChart, FiCreditCard, FiStar, FiMap } from 'react-icons/fi';
import { BsBoxSeam, BsCurrencyDollar, BsShield } from 'react-icons/bs';
import { MdOutlineSupervisorAccount, MdHome, MdDataUsage, MdReport } from 'react-icons/md';
import { HiOutlineRefresh } from 'react-icons/hi';
import { GrLocation } from 'react-icons/gr';
import avatar from './avatar.png';
import avatar2 from './avatar2.jpg';
import avatar3 from './avatar3.png';
import avatar4 from './avatar4.jpg';
import product4 from './product4.jpg';
import product5 from './product5.jpg';
import product6 from './product6.jpg';
import product7 from './product7.jpg';
import source from './source.png';

export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl h-20 md:ml-3"
      src={props.ProductImage}
      alt="order-item"
    />
  </div>
);

export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.Status}
  </button>
);
/*
export const kanbanGrid = [
  { headerText: 'To Do',
    keyField: 'Open',
    allowToggle: true },

  { headerText: 'In Progress',
    keyField: 'InProgress',
    allowToggle: true },

  { headerText: 'Testing',
    keyField: 'Testing',
    allowToggle: true,
    isExpanded: false },

  { headerText: 'Done',
    keyField: 'Close',
    allowToggle: true },
];
*/
const gridEmployeeProfile = (props) => (
  <div className="flex items-center gap-2">
    <img
      className="rounded-full w-10 h-10"
      src={props.EmployeeImage}
      alt="employee"
    />
    <p>{props.Name}</p>
  </div>
);

const gridEmployeeCountry = (props) => (
  <div className="flex items-center justify-center gap-2">
    <GrLocation />
    <span>{props.Country}</span>
  </div>
);
export const EditorData = () => (
  <div>
    <h3>
      Try React
      React has been designed from the start for gradual adoption, and you can use as little or as much React as you need. Whether you want to get a taste of React, add some interactivity to a simple HTML page, or start a complex React-powered app, the links in this section will help you get started.

      Online Playgrounds
      If you’re interested in playing around with React, you can use an online code playground. Try a Hello World template on CodePen, CodeSandbox, or Stackblitz.

      If you prefer to use your own text editor, you can also download this HTML file, edit it, and open it from the local filesystem in your browser. It does a slow runtime code transformation, so we’d only recommend using this for simple demos.

      Add React to a Website
      You can add React to an HTML page in one minute. You can then either gradually expand its presence, or keep it contained to a few dynamic widgets.

      Create a New React App
      When starting a React project, a simple HTML page with script tags might still be the best option. It only takes a minute to set up!

      As your application grows, you might want to consider a more integrated setup. There are several JavaScript toolchains we recommend for larger applications. Each of them can work with little to no configuration and lets you take full advantage of the rich React ecosystem. Learn how.

      Learn React
      People come to React from different backgrounds and with different learning styles. Whether you prefer a more theoretical or a practical approach, we hope you’ll find this section helpful.

      If you prefer to learn by doing, start with our practical tutorial.
      If you prefer to learn concepts step by step, start with our guide to main concepts.
      Like any unfamiliar technology, React does have a learning curve. With practice and some patience, you will get the hang of it.

      First Examples
      The React homepage contains a few small React examples with a live editor. Even if you don’t know anything about React yet, try changing their code and see how it affects the result.

      React for Beginners
      If you feel that the React documentation goes at a faster pace than you’re comfortable with, check out this overview of React by Tania Rascia. It introduces the most important React concepts in a detailed, beginner-friendly way. Once you’re done, give the documentation another try!

      React for Designers
      If you’re coming from a design background, these resources are a great place to get started.

      JavaScript Resources
      The React documentation assumes some familiarity with programming in the JavaScript language. You don’t have to be an expert, but it’s harder to learn both React and JavaScript at the same time.

      We recommend going through this JavaScript overview to check your knowledge level. It will take you between 30 minutes and an hour but you will feel more confident learning React.
    </h3>
  </div>
);
const customerGridImage = (props) => (
  <div className="image flex gap-4">
    <img
      className="rounded-full w-10 h-10"
      src={props.CustomerImage}
      alt="employee"
    />
    <div>
      <p>{props.CustomerName}</p>
      <p>{props.CustomerEmail}</p>
    </div>
  </div>
);

const customerGridStatus = (props) => (
  <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
    <p style={{ background: props.StatusBg }} className="rounded-full h-3 w-3" />
    <p>{props.Status}</p>
  </div>
);
export const areaPrimaryXAxis = {
  valueType: 'DateTime',
  labelFormat: 'y',
  majorGridLines: { width: 0 },
  intervalType: 'Years',
  edgeLabelPlacement: 'Shift',
  labelStyle: { color: 'gray' },
};

export const areaPrimaryYAxis = {
  labelFormat: '{value}%',
  lineStyle: { width: 0 },
  maximum: 4,
  interval: 1,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelStyle: { color: 'gray' },

};
export const barPrimaryXAxis = {
  valueType: 'Category',
  interval: 1,
  majorGridLines: { width: 0 },
};
export const barPrimaryYAxis = {
  majorGridLines: { width: 0 },
  majorTickLines: { width: 0 },
  lineStyle: { width: 0 },
  labelStyle: { color: 'transparent' },
};
const areaChartData = [
  [
    { x: new Date(2016, 0, 1), y: 2.2 },
    { x: new Date(2017, 0, 1), y: 3.4 },
    { x: new Date(2018, 0, 1), y: 2.8 },
    { x: new Date(2019, 0, 1), y: 1.6 },
    { x: new Date(2020, 0, 1), y: 2.3 },
    { x: new Date(2021, 0, 1), y: 2.5 },
    { x: new Date(2022, 0, 1), y: 2.9 },
  ],
  [
    { x: new Date(2016, 0, 1), y: 2 },
    { x: new Date(2017, 0, 1), y: 1.7 },
    { x: new Date(2018, 0, 1), y: 1.8 },
    { x: new Date(2019, 0, 1), y: 2.1 },
    { x: new Date(2020, 0, 1), y: 2.3 },
    { x: new Date(2021, 0, 1), y: 1.7 },
    { x: new Date(2022, 0, 1), y: 1.5 },
  ],
  [
    { x: new Date(2016, 0, 1), y: 0.8 },
    { x: new Date(2017, 0, 1), y: 1.3 },
    { x: new Date(2018, 0, 1), y: 1.1 },
    { x: new Date(2019, 0, 1), y: 1.6 },
    { x: new Date(2020, 0, 1), y: 2 },
    { x: new Date(2021, 0, 1), y: 1.7 },
    { x: new Date(2022, 0, 1), y: 2.3 },  
  ]
];

export const areaCustomSeries = [
  {
    dataSource: areaChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'COVID-19',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',

  },
  {
    dataSource: areaChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'CCHF',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',
  },
  {
    dataSource: areaChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Chikungunya',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',
  },
];

export const barChartData = [
  [
    { x: 'Covid', y: 46 },
    { x: 'CCHF', y: 27 },
    { x: 'Anthrax', y: 26 },
  ],
  [
    { x: 'Covid', y: 37 },
    { x: 'CCHF', y: 23 },
    { x: 'Anthrax', y: 18 },
  ],
  [
    { x: 'Covid', y: 38 },
    { x: 'CCHF', y: 17 },
    { x: 'Anthrax', y: 26 },
  ],
];

export const barCustomSeries = [
  {
    dataSource: barChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Covid-19',
    type: 'Column',
    marker: {
      dataLabel: {
        visible: true,
        position: 'Top',
        font: { fontWeight: '600', color: '#ffffff' },
      },
    },
  },
  {
    dataSource: barChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'CCHF',
    type: 'Column',
    marker: {
      dataLabel: {
        visible: true,
        position: 'Top',
        font: { fontWeight: '600', color: '#ffffff' },
      },
    },
  },
  {
    dataSource: barChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Anthrax',
    type: 'Column',
    marker: {
      dataLabel: {
        visible: true,
        position: 'Top',
        font: { fontWeight: '600', color: '#ffffff' },
      },
    },
  },
];
export const colorMappingData = [
  [
    { x: 'Jan', y: 6.96 },
    { x: 'Feb', y: 8.9 },
    { x: 'Mar', y: 12 },
    { x: 'Apr', y: 17.5 },
    { x: 'May', y: 22.1 },
    { x: 'June', y: 25 },
    { x: 'July', y: 29.4 },
    { x: 'Aug', y: 29.6 },
    { x: 'Sep', y: 25.8 },
    { x: 'Oct', y: 21.1 },
    { x: 'Nov', y: 15.5 },
    { x: 'Dec', y: 9.9 },
  ],
  ['#FFFF99'],
  ['#FFA500'],
  ['#FF4040'],
];

export const rangeColorMapping = [
  { label: '1°C to 10°C',
    start: '1',
    end: '10',
    colors: colorMappingData[1] },

  { label: '11°C to 20°C',
    start: '11',
    end: '20',
    colors: colorMappingData[2] },

  { label: '21°C to 30°C',
    start: '21',
    end: '30',
    colors: colorMappingData[3] },

];

export const ColorMappingPrimaryXAxis = {
  valueType: 'Category',
  majorGridLines: { width: 0 },
  title: 'Months',
};

export const ColorMappingPrimaryYAxis = {
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelFormat: '{value}°C',
  title: 'Temperature',
};

export const FinancialPrimaryXAxis = {
  valueType: 'DateTime',
  minimum: new Date('2016, 12, 31'),
  maximum: new Date('2017, 9, 30'),
  crosshairTooltip: { enable: true },
  majorGridLines: { width: 0 },
};

export const FinancialPrimaryYAxis = {
  title: 'Price',
  minimum: 100,
  maximum: 180,
  interval: 20,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
};

export const LinePrimaryXAxis = {
  valueType: 'DateTime',
  labelFormat: 'y',
  intervalType: 'Months',
  edgeLabelPlacement: 'Shift',
  majorGridLines: { width: 0 },
  background: 'white',
};

export const LinePrimaryYAxis = {
  labelFormat: '{value}',
  rangePadding: 'None',
  minimum: 0,
  maximum: 250,
  interval: 50,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const LinePriorityXAxis = {
  valueType: 'DateTime',
  labelFormat: 'y',
  intervalType: 'Months',
  edgeLabelPlacement: 'Shift',
  majorGridLines: { width: 0 },
  background: 'white',
};

export const LinePriorityYAxis = {
  labelFormat: '{value}',
  rangePadding: 'None',
  minimum: 0,
  maximum: 8200,
  interval: 1000,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const DiseasesXAxis = {
  valueType:'Disease',
  labelFormat: 'y',
  intervalType:'Diseases',
  majorGridLines:{width:0},
  background:'white',
};

export const DiseasesYAxis = {
  labelFormat: '{value}',
  rangePadding: 'None',
  minimum: 0,
  maximum: 250,
  interval: 50,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const customersGrid = [
  { type: 'checkbox', width: '50' },
  { headerText: 'Name',
    width: '150',
    template: customerGridImage,
    textAlign: 'Center' },
  { field: 'ProjectName',
    headerText: 'Project Name',
    width: '150',
    textAlign: 'Center' },
  { field: 'Status',
    headerText: 'Status',
    width: '130',
    format: 'yMd',
    textAlign: 'Center',
    template: customerGridStatus },
  {
    field: 'Weeks',
    headerText: 'Weeks',
    width: '100',
    format: 'C2',
    textAlign: 'Center' },
  { field: 'Budget',
    headerText: 'Budget',
    width: '100',
    format: 'yMd',
    textAlign: 'Center' },

  { field: 'Location',
    headerText: 'Location',
    width: '150',
    textAlign: 'Center' },

  { field: 'CustomerID',
    headerText: 'Customer ID',
    width: '120',
    textAlign: 'Center',
    isPrimaryKey: true,
  },

];

export const employeesGrid = [
  { headerText: 'Employee',
    width: '150',
    template: gridEmployeeProfile,
    textAlign: 'Center' },
  { field: 'Name',
    headerText: '',
    width: '0',
    textAlign: 'Center',
  },
  { field: 'Title',
    headerText: 'Designation',
    width: '170',
    textAlign: 'Center',
  },
  { headerText: 'Country',
    width: '120',
    textAlign: 'Center',
    template: gridEmployeeCountry },

  { field: 'HireDate',
    headerText: 'Hire Date',
    width: '135',
    format: 'yMd',
    textAlign: 'Center' },

  { field: 'ReportsTo',
    headerText: 'Reports To',
    width: '120',
    textAlign: 'Center' },
  { field: 'EmployeeID',
    headerText: 'Employee ID',
    width: '125',
    textAlign: 'Center' },
];

export const links = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'Home',
        to: 'Home',
        icon: <MdHome />,
      },
    ],
  },  
  {
    title: 'Data Sources',
    links: [
      {
        name: 'DHIS - 2 (Punjab)',
        to: 'DHIS2Punjab',
        icon: <MdHome />,
      },
    ],
  },

  {
    title: 'Diseases',
    links: [
      {
        name: 'Zoonotic Diseases',
        to: 'Zoonotic',
        icon: <MdDataUsage />,
      },
      {
        name: 'Vector Borne Diseases',
        to: 'VectorBorne',
        icon: <MdDataUsage />,
      },
      {
        name: 'Water Borne Diseases',
        to: 'Waterborne',
        icon: <MdDataUsage />,
      },
      {
        name: 'Pandemic Diseases',
        to:'Pandemic',
        icon: <MdDataUsage />,
      },
    ],
  },
  {
    title: 'Documentations',
    links: [
      {
        name: 'Geo Tags / Locations',
        to: 'GeoTags',
        icon: <MdReport />,
      },
      {
        name: 'Important Links',
        to:'DataSources',
        icon: <MdReport />,
      },
      {
        name: 'Definitions',
        to: 'Definitions',
        icon: <MdReport />,
      },
      {
        name: 'Map Representation',
        to:'Maps',
        icon:<MdReport/>,
      }
    ],
  },
];

export const cartData = [
  {
    image:
      product5,
    name: 'butterscotch ice-cream',
    category: 'Milk product',
    price: '$250',
  },
  {
    image:
      product6,
    name: 'Supreme fresh tomato',
    category: 'Vegetable Item',
    price: '$450',
  },
  {
    image:
      product7,
    name: 'Red color candy',
    category: 'Food Item',
    price: '$190',
  },
];

export const chatData = [
  {
    image:source,
    message: 'New Covid-19 case found in Attock',
    desc: 'Email concerned departments',
    time: '9:08 AM',
  },
  {
    image:source,
    message: 'Malaria cases rapidly increases in rural areas',
    desc: 'Email concerned departments',
    time: '11:56 AM',
  },
  {
    image:source,
    message: 'Meeting with Clients',
    desc: 'Set a schedule',
    time: '4:39 AM',
  },
];

export const earningData = [
  {
    icon: <MdOutlineSupervisorAccount />,
    amount: '39,354',
    percentage: '-4%',
    title: 'Customers',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    pcColor: 'red-600',
  },
  {
    icon: <BsBoxSeam />,
    amount: '4,396',
    percentage: '+23%',
    title: 'Products',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',
    pcColor: 'green-600',
  },
  {
    icon: <FiBarChart />,
    amount: '423,39',
    percentage: '+38%',
    title: 'Sales',
    iconColor: 'rgb(228, 106, 118)',
    iconBg: 'rgb(255, 244, 229)',

    pcColor: 'green-600',
  },
  {
    icon: <HiOutlineRefresh />,
    amount: '39,354',
    percentage: '-12%',
    title: 'Refunds',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
    pcColor: 'red-600',
  },
];

export const priorityOHDiseases = [
  {
    icon: <BsShield />,
    amount: '+1',
    title: 'COVID-19',
    desc: 'Attock',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    pcColor: 'green-600',
    to:'/pandemic/coronas'
  },
  {
    icon: <BsShield />,
    amount: '+3',
    desc: 'All Over Punjab',
    title: 'Dengue',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
    pcColor: 'red-600',
    to:'/vectorborne/dengues'
  },
  {
    icon: <BsShield />,
    amount: '+46',
    title: 'Rabies',
    desc: 'All Over Punjab',

    iconColor: 'rgb(228, 106, 118)',
    iconBg: 'rgb(255, 244, 229)',
    pcColor: 'green-600',
    to:'/zoonotic/rabies'
  },
  {
    icon: <BsShield />,
    amount: '+0',
    title: 'Anthrax',
    desc: 'All Over Punjab',
    iconColor: 'rgb(228, 106, 200)',
    iconBg: 'rgb(255, 214, 229)',
    pcColor: 'green-600',
    to:'/zoonotic/anthraxes'
  },
  {
    icon: <BsShield />,
    amount: '+0',
    title: 'CCHF',
    desc: 'All Over Punjab',
    iconColor: 'rgb(228, 106, 220)',
    iconBg: 'rgb(255, 214, 29)',
    pcColor: 'Blue-600',
    to:'/vectorborne/cchfs'
  },
  {
    icon: <BsShield />,
    amount: '+0',
    title: 'Salmonelosis',
    desc: 'All Over Punjab',
    iconColor: 'rgb(228, 106, 240)',
    iconBg: 'rgb(255, 214, 122)',
    pcColor: 'Red-600',
    to:'/zoonotic/salmonelloses'
  },
];

export const otherOHDiseases = [
  {
    icon: <FiStar />,
    amount: '+19',
    title: 'Leishmaniasis',
    desc: 'All over Punjab',
    iconBg: '#FB9678',
    pcColor: 'red-600',
    to:'/vectorborne/leishmaniases'
  },
  {
    icon: <FiStar />,
    amount: '+1',
    title: 'Malaria',
    desc: 'All over Punjab',
    iconBg: 'rgb(254, 201, 15)',
    pcColor: 'red-600',
    to:'/vectorborne/malarias'
  },
  {
    icon: <FiStar />,
    amount: '+0',
    title: 'Avian Influenza',
    desc: 'All over Punjab',
    iconBg: '#00C292',
    pcColor: 'green-600',
    to:'/pandemic/avianinfluenzas'
  },
  {
    icon: <FiStar />,
    amount: '+0',
    title: 'Chikungunya',
    desc: 'All over Punjab',
    iconBg: '#00C112',
    pcColor: 'green-600',
    to:'/vectorborne/chikungunyas'
  },
  {
    icon: <FiStar />,
    amount: '+0',
    title: 'Suspected TB',
    desc: 'All over Punjab',
    iconBg: '#00C722',
    pcColor: 'green-600',
    to:'/zoonotic/bovinetuberculoses'
  },
  {
    icon: <FiStar />,
    amount: '+0',
    title: 'Brucellosis',
    desc: 'All over Punjab',
    iconBg: '#00C252',
    pcColor: 'green-600',
    to:'/zoonotic/brucelloses'
  },
  {
    icon: <FiStar />,
    amount: '+0',
    title: 'Amebiasis',
    desc: 'All over Punjab',
    iconBg: '#00C272',
    pcColor: 'Blue-600',
    to:'/waterborne/amebiases'
  },
];

export const productsPerformance = [
  {
    image:
      product5,
    title: 'Is it good butterscotch ice-cream?',
    desc: 'Ice-Cream, Milk, Powder',
    rating: 'Good',
    itemSold: '65%',
    earningAmount: '$546,000',
  },
  {
    image:
      product6,
    title: 'Supreme fresh tomato available',
    desc: 'Market, Mall',
    rating: 'Excellent',
    itemSold: '98%',
    earningAmount: '$780,000',
  },
  {
    image:
      product7,
    title: 'Red color candy from Gucci',
    desc: 'Chocolate, Yummy',
    rating: 'Average',
    itemSold: '46%',
    earningAmount: '$457,000',
  },
  {
    image:
      product4,
    title: 'Stylish night lamp for night',
    desc: 'Electric, Wire, Current',
    rating: 'Poor',
    itemSold: '23%',
    earningAmount: '$123,000',
  },
];

export const medicalproBranding = {
  data: [
    {
      title: 'Due Date',
      desc: 'Oct 23, 2021',
    },
    {
      title: 'Budget',
      desc: '$98,500',
    },
    {
      title: 'Expense',
      desc: '$63,000',
    },
  ],
  teams: [
    {
      name: 'Bootstrap',
      color: 'orange',
    },
    {
      name: 'Angular',
      color: '#FB9678',
    },
  ],
  leaders: [
    {
      image:
        avatar2,
    },
    {
      image:
        avatar3,
    },
    {
      image:
        avatar2,
    },
    {
      image:
        avatar4,
    },
    {
      image:
        avatar,
    },
  ],
};

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
];

export const userProfileData = [
  {
    icon: <BsCurrencyDollar />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
  },
  {
    icon: <BsShield />,
    title: 'My Inbox',
    desc: 'Messages & Emails',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
  },
  {
    icon: <FiCreditCard />,
    title: 'My Tasks',
    desc: 'To-do and Daily Tasks',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',
  },
];

export const definitionsGrid = [
  {
    field: 'No',
    headerText: 'No.',
    textAlign: 'Left',
    width: '20',
  },
  {
    field: 'Disease',
    headerText: 'Disease Name',
    width: '60',
    textAlign: 'Left',
  },
  {
    field: 'Definition',
    headerText: 'Definition',
    width: '400',
    textAlign: 'Left',
  },
  {
    field: 'Threshold',
    headerText: 'Threshold',
    width: '80',
    textAlign: 'Left',
  },
]

export const definitionsData = [
  {
    No:"1",
    Disease: 'Avian/Seasonal Influenza',
    Definition:"An acute respiratory infection with measured fever of 238°C with cough and with onset within the last 10 days",
    Threshold:"Three or more cases in one location",
  },
  {
    No:"2",
    Disease: 'Suspected Corona Virus',
    Definition:"Acute onset of any three or more of the following signs or symptoms like fever, dry cough, general weakness/fatigue, headache, myalgia, sore throat, coryza, dyspnea, anorexia/nausea/vomiting and diarrhea or exposure to the k own positive case/residing in high transmission area within 14 days of the onset symptoms",
    Threshold:"One suspected case",
  },
  {
    No:"3",
    Disease: 'Suspected Viral Hemorrhagic Fever (CCHF)',
    Definition:"Acute onset of illness with high grade fever (38.5° C) for > 3 days and < 10 days will any two of the following: hemorhagic or purpurio rash. nose bleed, blood in vomit sputum/ stool, other hemorrhagic symptoms; and no known predisposing factors for hemorrhagic manifestations among those in contact with a confirmed patient of handling animals and raw animal products and when fever does not respond to antibiotics or anti-malarial treatment",
    Threshold:"One suspected case",
  },
  {
    No:"4",
    Disease: 'Chikungunya',
    Definition:"Any person with acute onset fever >38.5c and severe arthralgia, myalgia not explained by other medical conditions",
    Threshold:"Three or more cases in one location",
  },
  {
    No:"5",
    Disease: 'Suspected Dengue Fever',
    Definition:"An acute febrile illness of 2-7 days duration with 2 or more of the following; headache, retro-orbital pain, myalgia (muscle pain) or arthralgia (joint pain)",
    Threshold:"Cluster of three suspected cases",
  },
  {
    No:"6",
    Disease: 'Cutaneous Leishmaniasis',
    Definition:"Appearance of one or more lesions, typically on uncovered parts of the body; the face neck, arms and legs, which begins as nodules and turn into skin ulcers eventually healing but leaving a depressed scar.",
    Threshold:"One suspected case in non-endemic areas and a cluster of 3 cases in endemic areas",
  },
  {
    No:"7",
    Disease: 'Suspected Malaria',
    Definition:"Any person with fever or history of fever within the past 48 hours (with or without other symptoms such as nausea, vomiting and diarrhea, headache, back pain, chills, myalgia) in whom other obvious causes of fever have been excluded.",
    Threshold:"Case count more than 2 times mean number of cases of the previous weeks for a reporting unit",
  },
  {
    No:"8",
    Disease: 'Anthrax',
    Definition:"Any case that is compatible with the clinical description of cutaneous, pulmonary, gastrointestinal, injectional or meningeal Anthrax and had epidemiological link to confirm or suspected animal case or contaminated animal products.",
    Threshold:"One suspected case",
  },
  {
    No:"9",
    Disease: 'Suspected TB',
    Definition:"A patient who represents with symptoms or signs suggestive of TB as: A. Patient with cough of 3 weeks or longer, OR B. Cough less than 3 weeks with symptoms: o Sputum (Blood stained or without blood) o Fever followed by sweating usually at night o Loss of weight and appetite o A History of previous TB in a parent or TB history in family or close contact",
    Threshold:"One case or clinically suspected",
  },
  {
    No:"10",
    Disease: 'Brucellosis',
    Definition:"A case with acute or insidious onset fever, night sweats, undue fatigue anorexia, weight loss, head ache, and joint pain and is epidemiological linked to suspected or confirmed animal cases or contaminated food of animal origin.",
    Threshold:"-",
  },
  {
    No:"11",
    Disease: 'Dog Bite/Rabies',
    Definition:"Any case with acute onset of neurologiealayridrome (encephalitis) dominaled by any or more of the following symptoma; excitability, aerophobin, paralysis, hydrophobia delirium convulsions, or hyperactivity (furious rabies) or paralytic syndromes (dumb rabies) that progresses towards respiratory failure, coma and death with history of bites or scratches or contact with saliva from a suspected animal during last 30 to 90 days,",
    Threshold:"One suspected case in a susceptible animal species and/or human",
  },
  {
    No:"12",
    Disease: 'Suspected Typhoid/ Salmonellosis',
    Definition:"Any person with acute febrile illness fever of at least 38°€ for 3 or more days with abdominal discomfort, fatigue and diarrhea/ constipation.",
    Threshold:"3 or more suspected cases",
  },
  {
    No:"13",
    Disease: 'Amebiasis/Dysentery',
    Definition:"Entamoeba histolytica causes amoebiasis, often known as amoebic dysentery. Amoebiasis can manifest itself in a variety of ways, including no symptoms, mild symptoms, or severe symptoms. Lethargy, weight loss, colonic ulcerations, stomach pain, diarrhoea, or bloody diarrhoea are all possible symptoms. Inflammation and ulceration of the colon can occur, along with tissue death or perforation, resulting in peritonitis.",
    Threshold:"-",
  },
  {
    No:"14",
    Disease: 'Scabies',
    Definition:"The mite Sarcoptesscabiei causes scabies, which is a contagious skin infection. Severe itching and a pimple-like rash are the most prevalent symptoms. Tiny burrows may form on the skin from time to time.",
    Threshold:"-",
  },
]

export const entries1Grid = [
  {
    field:'disease',
    headerText:'Disease',
    width:'100',
    textAlign:'left'
  },
  {
    field:'hospitalDistrict',
    headerText:'Hospital District',
    width:'100',
    textAlign:'left'
  },
  {
    field:'facilityType',
    headerText:'Facility Type',
    width:'60',
    textAlign:'left'
  },
  {
    field:'date',
    headerText:'Date',
    width:'100',
    textAlign:'left'
  },
  {
    field:'age',
    headerText:'Age',
    width:'40',
    textAlign:'left'
  },
  {
    field:'gender',
    headerText:'Gender',
    width:'40',
    textAlign:'left'
  },
]

export const entriesGrid = [
  {
    field:'Provisional Diagnose',
    headerText:'Disease',
    width:'60',
    textAlign:'left'
  },
  {
    field:'Hospital Visited',
    headerText:'Facility',
    width:'60',
    textAlign:'left'
  },
  {
    field:'Facility Type',
    headerText:'Type',
    width:'20',
    textAlign:'left'
  },
  {
    field:'Gender',
    headerText:'Gender',
    width:'40',
    textAlign:'left'
  },
  {
    field:'Age',
    headerText:'Age',
    width:'20',
    textAlign:'left'
  },
  {
    field:'Town/Tehsil',
    headerText:'Location',
    width:'40',
    textAlign:'left'
  },
  {
    field:'Locality',
    headerText:'Patient Locality',
    width:'40',
    textAlign:'left'
  },
  {
    field:'Visit Date',
    headerText:'Date',
    textAlign:'left',
    width:'40',
  }
]

export const entriesGrid2 = [
  {
    field:'ProvisionalDiagnose',
    headerText:'Disease',
    width:'60',
    textAlign:'left'
  },
  {
    field:'HospitalVisited',
    headerText:'Facility',
    width:'60',
    textAlign:'left'
  },
  {
    field:'FacilityType',
    headerText:'Type',
    width:'20',
    textAlign:'left'
  },
  {
    field:'Gender',
    headerText:'Gender',
    width:'40',
    textAlign:'left'
  },
  {
    field:'AgeY',
    headerText:'Age',
    width:'20',
    textAlign:'left'
  },
  {
    field:'Town',
    headerText:'Location',
    width:'40',
    textAlign:'left'
  },
  {
    field:'Locality',
    headerText:'Patient Locality',
    width:'40',
    textAlign:'left'
  },
  {
    field:'VisitDate',
    headerText:'Date',
    textAlign:'left',
    width:'40',
  }
]

export const diseasesGrid = [
  {
    field: 'Disease',
    headerText: 'Disease',
    textAlign: 'Left',
    width: '80',
  },
  {
    field: 'Total',
    headerText: 'Total',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Jan',
    headerText: 'Jan',
    width: '80',
    textAlign: 'Center',
  },
  { field: 'Feb',
    headerText: 'Feb',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Mar',
    headerText: 'Mar',
    textAlign: 'Center',
    width: '80',
  },
  {
    headerText: 'Apr',
    field: 'Apr',
    textAlign: 'Center',
    width: '80',
  },
  {
    field: 'May',
    headerText: 'May',
    width: '80',
    textAlign: 'Center',
  },

  {
    field: 'June',
    headerText: 'June',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'July',
    headerText: 'July',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Aug',
    headerText: 'Aug',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Sept',
    headerText: 'Sept',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Oct',
    headerText: 'Oct',
    width: '80',
    textAlign: 'Center',
  }
];

export const DHIS2Grid = [
  {
    field: 'District',
    headerText: 'District',
    textAlign: 'Left',
    width: '120',
  },
  {
    field: 'Dengue',
    headerText: 'Dengue Fever',
    width: '80',
    textAlign: 'Center',
  },
  { field: 'Typhoid',
    headerText: 'Typhoid',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Dog Bite',
    headerText: 'Dog Bites',
    textAlign: 'Center',
    width: '80',
  },
  {
    headerText: 'Chikungunya',
    field: 'Chikungunya',
    textAlign: 'Center',
    width: '80',
  },
  {
    field: 'Leshmaniasis',
    headerText: 'Leshmaniasis',
    width: '80',
    textAlign: 'Center',
  },

  {
    field: 'Scabies',
    headerText: 'Scabies',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'TB',
    headerText: 'TB',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Dairrhea',
    headerText: 'Dairrhea',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Malaria',
    headerText: 'Malaria',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'CCHF',
    headerText: 'CCHF',
    width: '80',
    textAlign: 'Center',
  }
];

export const customersData = [
  {
    CustomerID: 1001,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1002,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1003,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1004,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1005,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1006,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1007,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1008,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1009,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1010,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1011,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1012,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1013,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1014,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1015,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1016,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1017,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1018,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1019,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1020,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1021,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1022,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1023,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1024,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1025,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1026,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1027,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1028,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1029,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1030,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1031,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1032,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1033,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1034,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1035,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1036,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1037,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1038,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1039,
    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1040,
    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },

];

export const employeesData = [
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar3,
  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar,
  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
];

export const dataSourcesGrid = [
  {
    field: 'No',
    headerText: 'No.',
    textAlign: 'Left',
    width: '10',
  },
  {
    field: 'DataSource',
    headerText: 'Short Form',
    textAlign: 'Left',
    width: '20',
  },
  {
    field: 'FullForm',
    headerText: 'Full Name',
    textAlign: 'Left',
    width: '50',
  },
  {
    field: 'Link',
    headerText: 'Link',
    textAlign: 'Left',
    width: '30',
  },
]

export const dataSourcesData = [
  {
    No:"1",
    DataSource: 'DSS',
    FullForm:"Disease Surveillance System, Punjab.",
    Link: "https://dss.punjab.gov.pk/",
  },
  {
    No:"2",
    DataSource: 'DHIS',
    FullForm:"District Health Information System, Punjab.",
    Link: "https://dhispb.com/",
  },
  {
    No:"3",
    DataSource: 'DHIS-2',
    FullForm:"District Health Information System - 2, Punjab.",
    Link: "https://dhispb.com/",
  },
  {
    No:"4",
    DataSource: 'ADRS',
    FullForm:"Animal Disease Reporting System, Punjab.",
    Link: "https://adrspunjab.gov.pk",
  },
  {
    No:"5",
    DataSource: 'COVID - 19',
    FullForm:"Dashboard for COVID 19, Governament Of Pakistan.",
    Link: "https://covid.gov.pk/",
  },
  {
    No:"6",
    DataSource: 'Dengue',
    FullForm:"Dashboard for Dengue Punjab",
    Link: "https://pitb.gov.pk/dats",
  },
  {
    No:"7",
    DataSource: 'MIS Dashboard',
    FullForm:"Health Management Information System",
    Link: "nhsrc.pk/index.php",
  },
]

export const importantLinksData = [
  {
    No:"1",
    DataSource: 'POHA',
    FullForm:"Pak One Health Alliance.",
    Link: "https://pakonehealth.org/",
  },
  {
    No:"2",
    DataSource: 'EBS',
    FullForm:"Event Based Surveillance System.",
    Link: "https://pakonehealth.org/ebs/",
  },
  {
    No:"3",
    DataSource: 'IDRS',
    FullForm:"Integrated Disease Surveillance and Response for Punjab",
    Link: "https://idsr.punjab.gov.pk/",
  },
  {
    No:"4",
    DataSource: 'WHO',
    FullForm:"World Health Organization.",
    Link: "https://www.who.int/",
  },
  {
    No:"5",
    DataSource: 'EP',
    FullForm:"Ending Pandemics.",
    Link: "https://endingpandemics.org",
  },
]

export const diseasesData = [
  {
    Disease: 'Avian Influenza',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'COVID - 19',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Anthrax',
    Location: 'New York',
    Status: 'active',
    StatusBg: '#03C9D7',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Scabies',
    StatusBg: '#FF5C8E',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Rabies',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Malaria',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Dengue',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Chikungunya',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'CCHF',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
    },
  {
    Disease: 'Brucellosis',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Amebiasis',

    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
    },
];


export const pandemicData = [
  {
    Disease: 'Avian Influenza',
    Jan: 1,
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: 1,
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total: '1',
  },
  {
    Disease: 'COVID - 19',
    Jan: 12,
    Feb: '-',
    Mar:1,
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total: '1',
  },
];

export const waterborneData = [
  {
    Disease: 'Amebiasis',

    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  '-',
    },  
    {
      Disease: 'Cholera',
      Jan:  '-',
      Feb: '-',
      Mar: '-',
      Apr: '-',
      May: '-',
      June: '-',
      July: '-',
      Aug: '-',
      Sept: '-',
      Oct: '-',
      Nov: '-',
      Dec: '-',
      Total:  8320,
      },
      {
        Disease: 'Hepatitis (A E)',
        Jan:  '-',
        Feb: '-',
        Mar: '-',
        Apr: '-',
        May: '-',
        June: '-',
        July: '-',
        Aug: '-',
        Sept: '-',
        Oct: '-',
        Nov: '-',
        Dec: '-',
        Total:  '392',
        },
        
      {
        Disease: 'Typhoid',
    
        Jan:  '-',
        Feb: '-',
        Mar: '-',
        Apr: '-',
        May: '-',
        June: '-',
        July: '-',
        Aug: '-',
        Sept: '-',
        Oct: '-',
        Nov: '-',
        Dec: '-',
        Total:  '1562',
        },

];

export const vectorborneData = [
  {
    Disease: 'Malaria',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: 196,
    Nov: 25,
    Dec: 69,
    Total:  '84',
  },
  {
    Disease: 'Dengue',
    Jan: 346,
    Feb:334,
    Mar:406,
    Apr:487,
    May:512,
    June:446,
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: 1866,
    Nov: 1681,
    Dec: 604,
    Total:  2376,
  },
  {
    Disease: 'Chikungunya',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  '-',
  },
  {
    Disease: 'CCHF',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  '-',
    },
    {
      Disease: 'Leshmaniasis',
      Jan:  '-',
      Feb: '-',
      Mar: '-',
      Apr: '-',
      May: '-',
      June: '-',
      July: '-',
      Aug: '-',
      Sept: '-',
      Oct: '-',
      Nov: '-',
      Dec: '-',
      Total:  '132',
    },
    {
      Disease: 'Zika Virus',
      Jan:  '-',
      Feb: '-',
      Mar: '-',
      Apr: '-',
      May: '-',
      June: '-',
      July: '-',
      Aug: '-',
      Sept: '-',
      Oct: '-',
      Nov: '-',
      Dec: '-',
      Total:  '-',
    },
];

export const zoonoticData = [
  {
    Disease: 'Anthrax',
    Jan: '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  '-',
  },
  {
    Disease: 'Dog Bites',
    Jan: 1680,
    Feb:1609,
    Mar:1530,
    Apr:1537,
    May:1514,
    June:1432,
    July:31,
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total: 9333,
  },
  {
    Disease: 'Brucellosis',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total: '-',
  },  {
    Disease: 'Bovine Tuberculosis',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  1287,
  },
  {
    Disease: 'Salmonellosis',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  '-',
  },
];

export const avianinfluenza22 = [
  {
  HospitalDistrict: "Gujrat",
  HospitalVisited: "THQ Hospital Lalamusa",
  FacilityType: "THQ",
  ProvisionalDiagnose: "AVIAN INFLUENZA",
  VisitDate: "5/10/2022",
  Latitude: "1",
  Longitude: "1",
  Name: "Manza",
  AgeY: "45",
  AgeM: "0",
  Gender: "female",
  Town: "Kharian",
  Locality: "Lalamusa No.1, Kharian, Gujrat",
  PatientDistrict: "Gujrat"
}];

export const dhis2Dec = [
  {District: 'Lahore', Dengue : 1680},
]


export const dogBiteData = [
  {month: 'Jan', count : 1680},
  {month: 'Feb', count : 1609},
  {month: 'Mar', count : 1530},
  {month: 'Apr', count : 1537},
  {month: 'May', count : 1514},
  {month: 'Jun', count : 1432},
  {month: 'July', count : 31},
]

export const dengueData = [
  {month: 'Jan', count : 346},
  {month: 'Feb', count : 334},
  {month: 'Mar', count : 406},
  {month: 'Apr', count : 487},
  {month: 'May', count : 512},
  {month: 'Jun', count : 446},
  {month: 'July', count : 0},
]

export const scheduleData = [
  {
    Id: 1,
    Subject: 'Explosion of Betelgeuse Star',
    Location: 'Space Center USA',
    StartTime: '2021-01-10T04:00:00.000Z',
    EndTime: '2021-01-10T05:30:00.000Z',
    CategoryColor: '#1aaa55',
  },
  {
    Id: 2,
    Subject: 'Thule Air Crash Report',
    Location: 'Newyork City',
    StartTime: '2021-01-11T06:30:00.000Z',
    EndTime: '2021-01-11T08:30:00.000Z',
    CategoryColor: '#357cd2',
  },
  {
    Id: 3,
    Subject: 'Blue Moon Eclipse',
    Location: 'Space Center USA',
    StartTime: '2021-01-12T04:00:00.000Z',
    EndTime: '2021-01-12T05:30:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 4,
    Subject: 'Meteor Showers in 2021',
    Location: 'Space Center USA',
    StartTime: '2021-01-13T07:30:00.000Z',
    EndTime: '2021-01-13T09:00:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 5,
    Subject: 'Milky Way as Melting pot',
    Location: 'Space Center USA',
    StartTime: '2021-01-14T06:30:00.000Z',
    EndTime: '2021-01-14T08:30:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 6,
    Subject: 'Mysteries of Bermuda Triangle',
    Location: 'Bermuda',
    StartTime: '2021-01-14T04:00:00.000Z',
    EndTime: '2021-01-14T05:30:00.000Z',
    CategoryColor: '#f57f17',
  },
  {
    Id: 7,
    Subject: 'Glaciers and Snowflakes',
    Location: 'Himalayas',
    StartTime: '2021-01-15T05:30:00.000Z',
    EndTime: '2021-01-15T07:00:00.000Z',
    CategoryColor: '#1aaa55',
  },
  {
    Id: 8,
    Subject: 'Life on Mars',
    Location: 'Space Center USA',
    StartTime: '2021-01-16T03:30:00.000Z',
    EndTime: '2021-01-16T04:30:00.000Z',
    CategoryColor: '#357cd2',
  },
  {
    Id: 9,
    Subject: 'Alien Civilization',
    Location: 'Space Center USA',
    StartTime: '2021-01-18T05:30:00.000Z',
    EndTime: '2021-01-18T07:30:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 10,
    Subject: 'Wildlife Galleries',
    Location: 'Africa',
    StartTime: '2021-01-20T05:30:00.000Z',
    EndTime: '2021-01-20T07:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 11,
    Subject: 'Best Photography 2021',
    Location: 'London',
    StartTime: '2021-01-21T04:00:00.000Z',
    EndTime: '2021-01-21T05:30:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 12,
    Subject: 'Smarter Puppies',
    Location: 'Sweden',
    StartTime: '2021-01-08T04:30:00.000Z',
    EndTime: '2021-01-08T06:00:00.000Z',
    CategoryColor: '#f57f17',
  },
  {
    Id: 13,
    Subject: 'Myths of Andromeda Galaxy',
    Location: 'Space Center USA',
    StartTime: '2021-01-06T05:00:00.000Z',
    EndTime: '2021-01-06T07:00:00.000Z',
    CategoryColor: '#1aaa55',
  },
  {
    Id: 14,
    Subject: 'Aliens vs Humans',
    Location: 'Research Center of USA',
    StartTime: '2021-01-05T04:30:00.000Z',
    EndTime: '2021-01-05T06:00:00.000Z',
    CategoryColor: '#357cd2',
  },
  {
    Id: 15,
    Subject: 'Facts of Humming Birds',
    Location: 'California',
    StartTime: '2021-01-19T04:00:00.000Z',
    EndTime: '2021-01-19T05:30:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 16,
    Subject: 'Sky Gazers',
    Location: 'Alaska',
    StartTime: '2021-01-22T05:30:00.000Z',
    EndTime: '2021-01-22T07:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 17,
    Subject: 'The Cycle of Seasons',
    Location: 'Research Center of USA',
    StartTime: '2021-01-11T00:00:00.000Z',
    EndTime: '2021-01-11T02:00:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 18,
    Subject: 'Space Galaxies and Planets',
    Location: 'Space Center USA',
    StartTime: '2021-01-11T11:30:00.000Z',
    EndTime: '2021-01-11T13:00:00.000Z',
    CategoryColor: '#f57f17',
  },
  {
    Id: 19,
    Subject: 'Lifecycle of Bumblebee',
    Location: 'San Fransisco',
    StartTime: '2021-01-14T00:30:00.000Z',
    EndTime: '2021-01-14T02:00:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 20,
    Subject: 'Alien Civilization',
    Location: 'Space Center USA',
    StartTime: '2021-01-14T10:30:00.000Z',
    EndTime: '2021-01-14T12:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 21,
    Subject: 'Alien Civilization',
    Location: 'Space Center USA',
    StartTime: '2021-01-10T08:30:00.000Z',
    EndTime: '2021-01-10T10:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 22,
    Subject: 'The Cycle of Seasons',
    Location: 'Research Center of USA',
    StartTime: '2021-01-12T09:00:00.000Z',
    EndTime: '2021-01-12T10:30:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 23,
    Subject: 'Sky Gazers',
    Location: 'Greenland',
    StartTime: '2021-01-15T09:00:00.000Z',
    EndTime: '2021-01-15T10:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 24,
    Subject: 'Facts of Humming Birds',
    Location: 'California',
    StartTime: '2021-01-16T07:00:00.000Z',
    EndTime: '2021-01-16T09:00:00.000Z',
    CategoryColor: '#7fa900',
  },
];

export const lineChartData = [
  [
    { x: new Date(2021, 1, 1), y: 43 },
    { x: new Date(2021, 4, 4), y: 17 },
    { x: new Date(2021, 7, 7), y: 52 },
    { x: new Date(2021, 10, 10), y: 50 },
    { x: new Date(2022, 1, 1), y: 242 },
    { x: new Date(2022, 4, 4), y: 19 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 1 },
    { x: new Date(2021, 7, 7), y: 0 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],

  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
];

export const linePriorityData = [
  [
    { x: new Date(2021, 1, 1), y: 20 },
    { x: new Date(2021, 4, 4), y: 42 },
    { x: new Date(2021, 7, 7), y: 4 },
    { x: new Date(2021, 10, 10), y: 4 },
    { x: new Date(2022, 1, 1), y: 1 },
    { x: new Date(2022, 4, 4), y: 0 },
    { x: new Date(2022, 7, 7), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 8157 },
    { x: new Date(2021, 4, 4), y: 5052 },
    { x: new Date(2021, 7, 7), y: 5341 },
    { x: new Date(2021, 10, 10), y: 4528 },
    { x: new Date(2022, 1, 1), y: 4819 },
    { x: new Date(2022, 4, 4), y: 4483 },
    { x: new Date(2022, 7, 7), y: 49 },
  ],

  [
    { x: new Date(2021, 1, 1), y: 1986 },
    { x: new Date(2021, 4, 4), y: 2199 },
    { x: new Date(2021, 7, 7), y: 1430 },
    { x: new Date(2021, 10, 10), y: 2300 },
    { x: new Date(2022, 1, 1), y: 1086 },
    { x: new Date(2022, 4, 4), y: 1444 },
    { x: new Date(2022, 7, 7), y: 3 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 0 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 3 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
    { x: new Date(2022, 7, 7), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 0 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 0 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
    { x: new Date(2022, 7, 7), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 0 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 0 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
    { x: new Date(2022, 7, 7), y: 0 },
  ],
];

export const dropdownData = [
  {
    Id: '1',
    Time: 'March 2021',
  },
  {
    Id: '2',
    Time: 'April 2021',
  }, {
    Id: '3',
    Time: 'May 2021',
  },
];

export const SparklineAreaData = [
  { x: 1, yval: 2 },
  { x: 2, yval: 6 },
  { x: 3, yval: 8 },
  { x: 4, yval: 5 },
  { x: 5, yval: 10 },

];

export const lineCustomSeries = [
  { dataSource: lineChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Leishmaniasis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'Malaria',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Avian Influenza',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

    { dataSource: lineChartData[3],
      xName: 'x',
      yName: 'y',
      name: 'Chikungunya',
      width: '2',
      marker: { visible: true, width: 10, height: 10 },
      type: 'Line' },

  { dataSource: lineChartData[4],
    xName: 'x',
    yName: 'y',
    name: 'TB',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[5],
    xName: 'x',
    yName: 'y',
    name: 'Brucellosis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

    { dataSource: lineChartData[6],
      xName: 'x',
      yName: 'y',
      name: 'Amebiasis',
      width: '2',
      marker: { visible: true, width: 10, height: 10 },
      type: 'Line' },
      
    { dataSource: lineChartData[7],
      xName: 'x',
      yName: 'y',
      name: 'Scabies',
      width: '2',
      marker: { visible: true, width: 10, height: 10 },
      type: 'Line' },
];

export const lineZoonoticSeries = [
  { dataSource: lineChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Anthrax',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'Bovine Tuberculosis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Brucellosis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

    { dataSource: lineChartData[3],
      xName: 'x',
      yName: 'y',
      name: 'Rabies',
      width: '2',
      marker: { visible: true, width: 10, height: 10 },
      type: 'Line' },

  { dataSource: lineChartData[4],
    xName: 'x',
    yName: 'y',
    name: 'Dog Bites',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[5],
    xName: 'x',
    yName: 'y',
    name: 'Salmonellosis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },
];

export const linePrioritySeries = [
  { dataSource: linePriorityData[0],
    xName: 'x',
    yName: 'y',
    name: 'COVID 19',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: linePriorityData[1],
    xName: 'x',
    yName: 'y',
    name: 'Dog Bites',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: linePriorityData[2],
    xName: 'x',
    yName: 'y',
    name: 'Dengue',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

    { dataSource: linePriorityData[3],
      xName: 'x',
      yName: 'y',
      name: 'Anthrax',
      width: '2',
      marker: { visible: true, width: 10, height: 10 },
      type: 'Line' },

  { dataSource: linePriorityData[4],
    xName: 'x',
    yName: 'y',
    name: 'Salmonellosis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: linePriorityData[5],
    xName: 'x',
    yName: 'y',
    name: 'CCHF',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },
];

export const pieChartData = [
  { x: 'CCHF', y: 18, text: '18%' },
  { x: 'Covid-19', y: 8, text: '8%' },
  { x: 'Chikungunya', y: 15, text: '15%' },
  { x: 'Anthrax', y: 11, text: '11%' },
  { x: 'Dengue', y: 18, text: '18%' },
  { x: 'Malaria', y: 14, text: '14%' },
  { x: 'Rabies', y: 16, text: '16%' },
];

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

export const ecomPieChartData = [
  { x: '2018', y: 18, text: '35%' },
  { x: '2019', y: 18, text: '15%' },
  { x: '2020', y: 18, text: '25%' },
  { x: '2021', y: 18, text: '25%' },
];

export const stackedChartData = [
  [
    { x: 'Q1 21', y: 1986 },
    { x: 'Q2 21', y: 2199 },
    { x: 'Q3 21', y: 1430 },
    { x: 'Q4 21', y: 2300 },
    { x: 'Q1 22', y: 1086 },
    { x: 'Q2 22', y: 1444 },
    { x: 'Q3 22', y: 3 },
    
  ],
];

export const stackedCustomSeries = [

  { dataSource: stackedChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Dengue Fever',
    type: 'StackingColumn',
    background: 'blue',

  },

];

export const stackedPrimaryXAxis = {
  majorGridLines: { width: 0 },
  minorGridLines: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  interval: 1,
  lineStyle: { width: 0 },
  labelIntersectAction: 'Rotate45',
  valueType: 'Category',
};

export const stackedPrimaryYAxis = {
  lineStyle: { width: 0 },
  minimum: 1000,
  maximum: 2500,
  interval: 500,
  majorTickLines: { width: 0 },
  majorGridLines: { width: 1 },
  minorGridLines: { width: 1 },
  minorTickLines: { width: 0 },
  labelFormat: '{value}',
};

export const hospDataGrid = [
  {
    field: 'group',
    headerText: 'District',
    width: '20',
    textAlign: 'Left',
  },
  {
    field: 'group_series_0',
    headerText: 'Hospital Name',
    width: '140',
    textAlign: 'Left',
  },
];


export const hospGrid = [
  {
    field: 'id',
    headerText: 'Sr no.',
    textAlign: 'Left',
    width: '15',
  },
  {
    field: 'district',
    headerText: 'District',
    width: '20',
    textAlign: 'Left',
  },
  {
    field: 'Name',
    headerText: 'Hospital Name',
    width: '140',
    textAlign: 'Left',
  },
];
export const attockHosp = [
  {
    id:'1',
    district:'Attock',
    Name:'THQ Hospital Pindi Geb',
  },
  {
    id:'2',
    district:'Attock',
    Name:'THQ Hospital Jand',
  },
  {       
    id:'3',
    district:'Attock',
    Name:'THQ Hospital Hazro',    
  },
  {
    id:'4',
    district:'Attock',
    Name:'THQ Hospital Fateh Jang',    
  },
  {
    id:'5',
    district:'Chiniot',
    Name:'BHU 241 Mangoana',    
  },
  {
    id:'6',
    district:'Mian Wali',
    Name:'BHU Abba Khel',    
  },
  {
    id:'7',
    district:'Gujrawala',
    Name:'Al Raee Hospital',    
  },
  {
    id:'8',
    district:'Nankana Sahib',
    Name:'Al Fazal Children Hospital',    
  },
  {
    id:'9',
    district:'Faislabad',
    Name:'Al Shifa Medical Centre',    
  },
  {
    id:'10',
    district:'Sailkot',
    Name:'Allama Iqbal Memorial Hospital',    
  },
  {
    id:'11',
    district:'Faislabad',
    Name:'Allied Hospital, Jail Road',    
  },
  {
    id:'12',
    district:'Gujrat',
    Name:'Aziz Bhatti Saeed Hospital, Bhimber Road',    
  },
  {
    id:'13',
    district:'Bahawalpur',
    Name:'Bahawal Vectoria Hospital, Circular Road',    
  },
  {
    id:'13',
    district:'Vehari',
    Name:'BHU 1/WB',    
  },
  {
    id:'14',
    district:'Jhelum',
    Name:'BHU Adrana',    
  },
  {
    id:'15',
    district:'Faislabad',
    Name:'Children Hospital',    
  },
  {
    id:'16',
    district:'Sialkot',
    Name:'Civil Hospital',    
  },
];

export const hospitalList = [
  {group:"Attock",group_series_0:"DHQ Hospital Attock"},
  {group:"Attock",group_series_0:"THQ Hassan Abdal"},
  {group:"Attock",group_series_0:"THQ Hospital Fateh Jang"},
  {group:"Attock",group_series_0:"THQ Hospital Hazro"},
  {group:"Attock",group_series_0:"THQ Hospital Jand"},
  {group:"Attock",group_series_0:"THQ Hospital Pindi Gheb"},
  {group:"Bahawalpur",group_series_0:"BHU BASTI RANA IQBAL"},
  {group:"Bahawalpur",group_series_0:"BHU CHAK NO. 39/DNB"},
  {group:"Bahawalpur",group_series_0:"BHU CHAK NO. 88/F"},
  {group:"Bahawalpur",group_series_0:"BHU CHAK NO.8/DNB"},
  {group:"Bahawalpur",group_series_0:"BHU HAKRA"},
  {group:"Bahawalpur",group_series_0:"BHU KOTLA QAIM KHAN"},
  {group:"Bahawalpur",group_series_0:"BHU SANJAR"},
  {group:"Bahawalpur",group_series_0:"BHU SYED IMAM SHAH"},
  {group:"Bahawalpur",group_series_0:"Bahawal Victoria Hospital, Circular Road, Bahawalpur"},
  {group:"Bahawalpur",group_series_0:"RHC KHUTRI BUNGLOW"},
  {group:"Bahawalpur",group_series_0:"RHC UCH SHARIF"},
  {group:"Bahawalpur",group_series_0:"THQ Hospital Ahmadpur East"},
  {group:"Bahawalpur",group_series_0:"THQ Hospital Hasilpur"},
  {group:"Bahawalpur",group_series_0:"THQ Khairpur Tamewali"},
  {group:"Bahawalpur",group_series_0:"THQ Yazman"},
  {group:"Bhakkar",group_series_0:"DHQ Hospital Bhakkar"},
  {group:"Bhakkar",group_series_0:"THQ Hospital Daryakhan"},
  {group:"Bhakkar",group_series_0:"THQ Hospital Kalurkot"},
  {group:"Bhakkar",group_series_0:"THQ Hospital Mankera"},
  {group:"Bhakkar",group_series_0:"edo Office Bhakkar"},
  {group:"Chakwal",group_series_0:"BHU NEELA"},
  {group:"Chakwal",group_series_0:"Ch. Pervaiz Elahi City Hospital Talagang"},
  {group:"Chakwal",group_series_0:"DHQ Chakwal"},
  {group:"Chakwal",group_series_0:"THQ Choa Saiden Shah"},
  {group:"Chakwal",group_series_0:"THQ TALAGANG"},
  {group:"Chakwal",group_series_0:"THQ Trauma Kallar Kahar"},
  {group:"Chakwal",group_series_0:"edo Office Chakwal"},
  {group:"Chiniot",group_series_0:"BHU 241 Mangoana"},
  {group:"Chiniot",group_series_0:"BHU AHMED ABAD"},
  {group:"Chiniot",group_series_0:"BHU Mathrooma"},
  {group:"Chiniot",group_series_0:"DHQ Hospital Chiniot"},
  {group:"Chiniot",group_series_0:"THQ Bhowana"},
  {group:"Chiniot",group_series_0:"THQ Lalian"},
  {group:"Chiniot",group_series_0:"edo Office Chiniot"},
  {group:"Dera Ghazi Khan",group_series_0:"BHU KHAKHI"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC CHOTI ZARIN"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC Kala"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC SARWAR WALI"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC SHAH SADAR DIN"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC SHAHDAN LUND"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC TIBBI QASRANI"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC VEHOVA"},
  {group:"Dera Ghazi Khan",group_series_0:"THQ Hospital Kot Chutta"},
  {group:"Dera Ghazi Khan",group_series_0:"THQ Hospital Taunsa"},
  {group:"Faisalabad",group_series_0:"Al-Shifa Medical Centre Faisalabad"},
  {group:"Faisalabad",group_series_0:"Allied Hospital, Jail Road, Faisalabad"},
  {group:"Faisalabad",group_series_0:"Children Hospital, Faisalabad"},
  {group:"Faisalabad",group_series_0:"DHQ Hospital, Mall Road, Faisalabad"},
  {group:"Faisalabad",group_series_0:"Government Teaching Hospital, Ghulam Muhammad Abad, Faisalabad"},
  {group:"Faisalabad",group_series_0:"Govt. General Hospital Samanabad"},
  {group:"Faisalabad",group_series_0:"RHC 469 GB"},
  {group:"Faisalabad",group_series_0:"RHC SATYANA"},
  {group:"Faisalabad",group_series_0:"THQ Hospital Jaranwala"},
  {group:"Faisalabad",group_series_0:"THQ Hospital Jhumra"},
  {group:"Faisalabad",group_series_0:"THQ Hospital Samanduri"},
  {group:"Faisalabad",group_series_0:"THQ Tandilianwala"},
  {group:"Gujranwala",group_series_0:"#<Hospital:0x0000000591d430>"},
  {group:"Gujranwala",group_series_0:"#<Hospital:0x007fcc98a873b8>"},
  {group:"Gujranwala",group_series_0:"Al Raee Hospital"},
  {group:"Gujranwala",group_series_0:"BHU BOTALA SHARAM SINGH"},
  {group:"Gujranwala",group_series_0:"BHU BUDDA GORRAYA"},
  {group:"Gujranwala",group_series_0:"BHU CHAK RAMDAS"},
  {group:"Gujranwala",group_series_0:"BHU DANDIAN"},
  {group:"Gujranwala",group_series_0:"BHU DARGA PUR"},
  {group:"Gujranwala",group_series_0:"BHU FEROZEWALA"},
  {group:"Gujranwala",group_series_0:"BHU KALI SUBA"},
  {group:"Gujranwala",group_series_0:"BHU LALU PUR"},
  {group:"Gujranwala",group_series_0:"BHU MOKHAL SINDAAWAN"},
  {group:"Gujranwala",group_series_0:"BHU PEERO CHAK"},
  {group:"Gujranwala",group_series_0:"BHU RAJA"},
  {group:"Gujranwala",group_series_0:"DHQ Teaching Hospital, Gujranwala"},
  {group:"Gujranwala",group_series_0:"MedcareÂ International Hospital"},
  {group:"Gujranwala",group_series_0:"RHC AHMAD NAGAR"},
  {group:"Gujranwala",group_series_0:"RHC ALI PUR CHATHA"},
  {group:"Gujranwala",group_series_0:"RHC EMINABAD"},
  {group:"Gujranwala",group_series_0:"RHC GHAKHAR"},
  {group:"Gujranwala",group_series_0:"RHC Kot Ladha"},
  {group:"Gujranwala",group_series_0:"RHC Ladhey Wala Waraich"},
  {group:"Gujranwala",group_series_0:"RHC RASOOL NAGAR"},
  {group:"Gujranwala",group_series_0:"RHC SOHDRA"},
  {group:"Gujranwala",group_series_0:"RHC WAHNDO"},
  {group:"Gujranwala",group_series_0:"THQ Hospital Kamoke"},
  {group:"Gujranwala",group_series_0:"THQ Hospital Wazirabad"},
  {group:"Gujranwala",group_series_0:"THQ Noshera Virkan"},
  {group:"Gujranwala",group_series_0:"Zeeshan Welfare Hospital"},
  {group:"Gujranwala",group_series_0:"edo Office Gujranwala"},
  {group:"Gujrat",group_series_0:"Aziz Bhatti Shaheed Hospital (DHQ), Bhimber Road, Gujrat"},
  {group:"Gujrat",group_series_0:"BHU BHAG NAGAR"},
  {group:"Gujrat",group_series_0:"BHU BOLANI"},
  {group:"Gujrat",group_series_0:"BHU CHECHIAN SHAMAS"},
  {group:"Gujrat",group_series_0:"BHU CHIRYAWALA"},
  {group:"Gujrat",group_series_0:"BHU DENDAI BESA"},
  {group:"Gujrat",group_series_0:"BHU GHAKHRA KALAN"},
  {group:"Gujrat",group_series_0:"BHU KARYALA"},
  {group:"Gujrat",group_series_0:"BHU KHOHAR"},
  {group:"Gujrat",group_series_0:"BHU LAKHANWAL"},
  {group:"Gujrat",group_series_0:"BHU MAKIANA"},
  {group:"Gujrat",group_series_0:"BHU SIR DHOKE"},
  {group:"Gujrat",group_series_0:"BHU THOOTHA RAI BAHADUR"},
  {group:"Gujrat",group_series_0:"RHC MALKA"},
  {group:"Gujrat",group_series_0:"THQ Hospital Kharian"},
  {group:"Gujrat",group_series_0:"THQ Hospital Kunjah"},
  {group:"Gujrat",group_series_0:"THQ Hospital Lalamusa"},
  {group:"Gujrat",group_series_0:"THQ Hospital Sarai Alamgir"},
  {group:"Gujrat",group_series_0:"THQ Hospital Trauma Center Lalamusa"},
  {group:"Hafizabad",group_series_0:"BHU Kot Nawan Kaghoka"},{group:"Hafizabad",group_series_0:"DHQ Hospital Hafizabad"},{group:"Hafizabad",group_series_0:"RHC Jalal Pur Bhattian"},{group:"Hafizabad",group_series_0:"RHC Kaleke Mandi"},{group:"Hafizabad",group_series_0:"RHC Kasooke"},{group:"Hafizabad",group_series_0:"RHC Kolo Tarar"},{group:"Hafizabad",group_series_0:"RHC Rasool Pur Tarar"},{group:"Hafizabad",group_series_0:"RHC Sukheke Mandi"},{group:"Hafizabad",group_series_0:"RHC Vanike Tarar"},{group:"Hafizabad",group_series_0:"THQ Pindi Bhattian"},
  {group:"Hafizabad",group_series_0:"Wahlah Family Hospital"},
  {group:"Hafizabad",group_series_0:"edo Office Hafizabad"},
  {group:"Jhang",group_series_0:"Chak No.170/JB"},
  {group:"Jhang",group_series_0:"DHQ Hospital Jhang"},
  {group:"Jhang",group_series_0:"Ismail Welfare Hospital"},
  {group:"Jhang",group_series_0:"RHC Mochiwala"},
  {group:"Jhang",group_series_0:"RHC Shah Jewena"},
  {group:"Jhang",group_series_0:"Ratta Matta"},
  {group:"Jhang",group_series_0:"THQ Ahmed pur Sial"},
  {group:"Jhang",group_series_0:"THQ Hospital Hazari"},
  {group:"Jhang",group_series_0:"THQ Hospital Shorkot"},
  {group:"Jhang",group_series_0:"edo Office Jhang"},
  {group:"Jhelum",group_series_0:"BHU Adrana"},
  {group:"Jhelum",group_series_0:"BHU Ahmad Abad"},
  {group:"Jhelum",group_series_0:"BHU Ban Shaheed"},
  {group:"Jhelum",group_series_0:"BHU Banth"},
  {group:"Jhelum",group_series_0:"BHU Bhatia"},
  {group:"Jhelum",group_series_0:"BHU Chak Jani"},
  {group:"Jhelum",group_series_0:"BHU Chak Khasa/ Ph # 0544-732489"},
  {group:"Jhelum",group_series_0:"BHU Chak Latif Ullah"},
  {group:"Jhelum",group_series_0:"BHU Chak Shadi"},
  {group:"Jhelum",group_series_0:"BHU Chotala"},
  {group:"Jhelum",group_series_0:"BHU Dara Pur"},
  {group:"Jhelum",group_series_0:"BHU Dharyala Jalip"},
  {group:"Jhelum",group_series_0:"BHU G U Singh"},
  {group:"Jhelum",group_series_0:"BHU Goal Pur"},
  {group:"Jhelum",group_series_0:"BHU Huran Pur"},
  {group:"Jhelum",group_series_0:"BHU Jallo Chak"},
  {group:"Jhelum",group_series_0:"BHU Jango Raryala"},
  {group:"Jhelum",group_series_0:"BHU Kala Gujran"},
  {group:"Jhelum",group_series_0:"BHU Kalvant Pur"},
  {group:"Jhelum",group_series_0:"BHU Krounta"},
  {group:"Jhelum",group_series_0:"BHU Kundwal"},
  {group:"Jhelum",group_series_0:"BHU Mota Gharbi"},
  {group:"Jhelum",group_series_0:"BHU Pail Mirza"},
  {group:"Jhelum",group_series_0:"BHU Phulray Sayedan"},
  {group:"Jhelum",group_series_0:"BHU Pindi Said Pur"},
  {group:"Jhelum",group_series_0:"BHU Pindory"},
  {group:"Jhelum",group_series_0:"BHU Sodi Gujjar"},
  {group:"Jhelum",group_series_0:"BHU Sohan"},
  {group:"Jhelum",group_series_0:"BHU Souwal"},
  {group:"Jhelum",group_series_0:"DHQ Hospital Jhelum"},
  {group:"Jhelum",group_series_0:"RHC Civil Hospital, Khewra, Pind Dadan Khan, Jhelum"},
  {group:"Jhelum",group_series_0:"RHC Dina"},
  {group:"Jhelum",group_series_0:"RHC Domeli"},
  {group:"Jhelum",group_series_0:"RHC Jalal Pur Sharif"},
  {group:"Jhelum",group_series_0:"RHC Khalas Pur"},
  {group:"Jhelum",group_series_0:"RHC Lilla"},
  {group:"Jhelum",group_series_0:"THQ Hospital PD Khan"},
  {group:"Jhelum",group_series_0:"THQ Hospital Sohawa"},
  {group:"Jhelum",group_series_0:"edo Office Jhelum"},
  {group:"Kasur",group_series_0:"BHU KHARA"},
  {group:"Kasur",group_series_0:"DHQ HOSPITAL KASUR"},
  {group:"Kasur",group_series_0:"Govt. Aziz Bibi Hospital Roshan Bhila"},
  {group:"Kasur",group_series_0:"RHC CHANGA MANGA"},
  {group:"Kasur",group_series_0:"RHC ELLAH ABAD."},
  {group:"Kasur",group_series_0:"RHC GANDA SINGH WALA"},
  {group:"Kasur",group_series_0:"RHC HABIBABAD"},
  {group:"Kasur",group_series_0:"RHC HALLA"},
  {group:"Kasur",group_series_0:"RHC JUARA"},
  {group:"Kasur",group_series_0:"RHC KANGAN PUR"},
  {group:"Kasur",group_series_0:"RHC KHUDIAN"},
  {group:"Kasur",group_series_0:"RHC MUSTAFA ABAD"},
  {group:"Kasur",group_series_0:"RHC PHOOL NAGAR"},
  {group:"Kasur",group_series_0:"RHC RAJA JANG"},
  {group:"Kasur",group_series_0:"THQ HOSPITAL CHUNIAN"},
  {group:"Kasur",group_series_0:"THQ HOSPITAL PATTOKI"},
  {group:"Kasur",group_series_0:"THQ Kot Radha Kishan"},
  {group:"Khanewal",group_series_0:"BHU CHAK NO. 67/10-R"},
  {group:"Khanewal",group_series_0:"BHU CHAK NO. 70/15-L"},
  {group:"Khanewal",group_series_0:"BHU CHAK NO. 92/10-R"},
  {group:"Khanewal",group_series_0:"BHU CHAK NO.139/10-R"},
  {group:"Khanewal",group_series_0:"BHU CHAK NO.9/V"},
  {group:"Khanewal",group_series_0:"BHU JASSO KANWAN"},
  {group:"Khanewal",group_series_0:"BHU SARDAR PUR"},
  {group:"Khanewal",group_series_0:"BHU SHAM KOT"},
  {group:"Khanewal",group_series_0:"DHQ Hospital Khanewal"},
  {group:"Khanewal",group_series_0:"RHC KACHA KHUH"},
  {group:"Khanewal",group_series_0:"RHC Makhdoom Pur, Khanewal, Khanewal"},
  {group:"Khanewal",group_series_0:"THQ Hospital Channu"},
  {group:"Khanewal",group_series_0:"THQ Hospital Jhanian"},
  {group:"Khanewal",group_series_0:"THQ Hospital Kabir wala"},
  {group:"Khanewal",group_series_0:"edo Office Kkhanewal"},
  {group:"Khushab",group_series_0:"DHQ Khushab at Jahurabad"},
  {group:"Khushab",group_series_0:"THQ HOSPITAL NOOR PUR THAL"},
  {group:"Khushab",group_series_0:"THQ HOSPITAL QAIDABAD"},
  {group:"Khushab",group_series_0:"edo Office Khushab"},
  {group:"Lodhran",group_series_0:"BHU AIN WAHIN"},
  {group:"Lodhran",group_series_0:"DHQ Hospital Lodhran"},
  {group:"Lodhran",group_series_0:"RHC CHAK NO.231/WB"},
  {group:"Lodhran",group_series_0:"RHC MEKHDOOM AALI"},
  {group:"Lodhran",group_series_0:"THQ Hospital Dunya pur"},
  {group:"Lodhran",group_series_0:"THQ Hospital Kahror Pacca"},
  {group:"Lodhran",group_series_0:"edo Office Lodhran"},
  {group:"Mandi Bahauddin",group_series_0:"#<Hospital:0x007f3e48f46860>"},
  {group:"Mandi Bahauddin",group_series_0:"BHU BARMUSA"},
  {group:"Mandi Bahauddin",group_series_0:"BHU BHAGOWAL"},
  {group:"Mandi Bahauddin",group_series_0:"BHU KADHAR"},
  {group:"Mandi Bahauddin",group_series_0:"BHU MANGAT"},
  {group:"Mandi Bahauddin",group_series_0:"BHU NAIN RANJHA"},
  {group:"Mandi Bahauddin",group_series_0:"DHQ Hospital Mandi Bahauddin"},
  {group:"Mandi Bahauddin",group_series_0:"RHC Jokalian"},
  {group:"Mandi Bahauddin",group_series_0:"RHC Malakwal"},
  {group:"Mandi Bahauddin",group_series_0:"RHC Miana Gondal"},
  {group:"Mandi Bahauddin",group_series_0:"RHC parhianwala"},
  {group:"Mandi Bahauddin",group_series_0:"THQ Hospital Malakwal"},
  {group:"Mandi Bahauddin",group_series_0:"THQ Hospital Phalia"},
  {group:"Mianwali",group_series_0:"BHU ABBA KHEL"},
  {group:"Mianwali",group_series_0:"DHQ Hospital Mianwali"},
  {group:"Mianwali",group_series_0:"RHC Musa Khel, Mianwali, Mianwali"},
  {group:"Mianwali",group_series_0:"THQ HOSPITAL ISA KHEL"},
  {group:"Mianwali",group_series_0:"THQ Hospital Piplan"},
  {group:"Mianwali",group_series_0:"THQ LEVEL HOSPITAL KALABAGH"},
  {group:"Mianwali",group_series_0:"edo Office Mianwali"},
  {group:"Multan",group_series_0:"Children Hospital, Multan"},
  {group:"Multan",group_series_0:"Govt. Mushtaq Land THQ Hosp. Jalapur Pirwala"},
  {group:"Multan",group_series_0:"Govt. THQ Hospital Shujabad"},
  {group:"Multan",group_series_0:"Mian Muhammad Shahbaz Sharif General Hospital"},
  {group:"Multan",group_series_0:"Nishtar Hospital, Nishtar Road, Multan"},
  {group:"Muzaffargarh",group_series_0:"BHU 518-TDA"},
  {group:"Muzaffargarh",group_series_0:"BHU 576-TDA"},
  {group:"Muzaffargarh",group_series_0:"BHU Basti Chunjan"},
  {group:"Muzaffargarh",group_series_0:"BHU Ghazi Ghatt"},
  {group:"Muzaffargarh",group_series_0:"BHU Karam Dad Qureshi"},
  {group:"Muzaffargarh",group_series_0:"BHU Kotla Gamoon"},
  {group:"Muzaffargarh",group_series_0:"DHQ Hospital Muzaffargarh"},
  {group:"Muzaffargarh",group_series_0:"RHC Chowck Sarwar Shaheed"},
  {group:"Muzaffargarh",group_series_0:"RHC Gujrat"},
  {group:"Muzaffargarh",group_series_0:"RHC Murad Abad"},
  {group:"Muzaffargarh",group_series_0:"RHC Shah Jamal"},
  {group:"Muzaffargarh",group_series_0:"RHC Sheher Sultan"},
  {group:"Muzaffargarh",group_series_0:"THQ Hospital Alipur"},
  {group:"Muzaffargarh",group_series_0:"THQ Hospital Kot Adu"},
  {group:"Muzaffargarh",group_series_0:"THQ Jatoi"},
  {group:"Muzaffargarh",group_series_0:"edo Office Muzaffargarh"},
  {group:"Okara",group_series_0:"DHQ Hospital (South City) Okara"},
  {group:"Okara",group_series_0:"DHQ Hospital Okara"},
  {group:"Okara",group_series_0:"RHC RENALA KHURD"},{group:"Okara",group_series_0:"RHC SHAH BORE"},
  {group:"Okara",group_series_0:"THQ HOSPITAL DEPALPUR"},
  {group:"Okara",group_series_0:"THQ HOSPITAL HAVALI LAKHA"},
  {group:"Okara",group_series_0:"edo Office Okara"},
  {group:"Sheikhupura",group_series_0:"DHQ Teaching Hospital, Sheikhupura"},
  {group:"Sheikhupura",group_series_0:"RHC Khanqah Dogran"},
  {group:"Sheikhupura",group_series_0:"RHC SHARAQ PUR SHARIF"},
  {group:"Sheikhupura",group_series_0:"THQ Hospital Ferozewala"},
  {group:"Sheikhupura",group_series_0:"THQ Hospital Muridke"},
  {group:"Sheikhupura",group_series_0:"THQ Hospital Safdarabad"},
  {group:"Sheikhupura",group_series_0:"THQ Hospital Sharaqpur Sharif"},
  {group:"Sialkot",group_series_0:"Allama Iqbal Memorial Hospital, Sialkot"},
  {group:"Sialkot",group_series_0:"Civil Hospital Daska"},
  {group:"Sialkot",group_series_0:"Government Sardar Begum Teaching Hospital, Sialkot"},
  {group:"Sialkot",group_series_0:"THQ Hospital Pasrur"},
  {group:"Sialkot",group_series_0:"THQ Kotli Loharan"},
  {group:"Sialkot",group_series_0:"THQ Sambrial"},
  {group:"Toba Tek Singh",group_series_0:"DHQ Hospital Toba Tek Singh"},
  {group:"Toba Tek Singh",group_series_0:"Govt. Eye-cum-General Hospital Gojra"},
  {group:"Toba Tek Singh",group_series_0:"THQ Hospital Kamalia"},
  {group:"Vehari",group_series_0:"BHU 1/WB"},
  {group:"Vehari",group_series_0:"BHU 122/WB"},
  {group:"Vehari",group_series_0:"BHU 155/WB"},
  {group:"Vehari",group_series_0:"BHU 158/WB"},
  {group:"Vehari",group_series_0:"BHU 187/WB"},
  {group:"Vehari",group_series_0:"BHU 22/WB"},
  {group:"Vehari",group_series_0:"BHU 44/kb"},
  {group:"Vehari",group_series_0:"BHU 477/EB"},
  {group:"Vehari",group_series_0:"BHU 5/WB"},
  {group:"Vehari",group_series_0:"BHU 535/EB"},
  {group:"Vehari",group_series_0:"BHU 569/EB"},
  {group:"Vehari",group_series_0:"BHU 69/WB"},
  {group:"Vehari",group_series_0:"BHU 88/WB"},
  {group:"Vehari",group_series_0:"BHU FATEH PUR"},
  {group:"Vehari",group_series_0:"BHU QADAR ABAD"},
  {group:"Vehari",group_series_0:"BHU SAHAR"},
  {group:"Vehari",group_series_0:"BHU SARGANA"},
  {group:"Vehari",group_series_0:"DHQ Hospital Vehari"},
  {group:"Vehari",group_series_0:"RHC 188/EB MIAN PAKHI"},
  {group:"Vehari",group_series_0:"RHC Garah More"},
  {group:"Vehari",group_series_0:"RHC TIBBA SULTAN PUR"},
  {group:"Vehari",group_series_0:"THQ Burewala"},
  {group:"Vehari",group_series_0:"THQ Mailsi"}
  ];

export const MapData = [
{
  type: "FeatureCollection",
  "crs": {
    type: "name",
    "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" }
  },
  features: [
    {
      type: "Feature",
      "properties": {
        "DIS_ID": 960,
        "FACILITYID": 28014,
        "NAME": "Bearbrook Skateboard Park",
        "NAME_FR": "Planchodrome Bearbrook",
        "ADDRESS": "8720 Russell Road",
        "ADDRESS_FR": "8720, chemin Russell",
        "FACILITY_T": "flat",
        "FACILITY_1": "plat",
        "ACCESSCTRL": "no/non",
        "ACCESSIBLE": "no/non",
        "OPEN": null,
        "NOTES": "Outdoor",
        "MODIFIED_D": "2018/01/18",
        "CREATED_DA": null,
        "FACILITY": "Neighbourhood : smaller size facility to service population of 10,000 or less",
        "FACILITY_F": "De voisinage : petite installation assurant des services à 10 000 résidents ou moins.",
        "DESCRIPTIO": "Flat asphalt surface, 5 components",
        "DESCRIPT_1": "Surface d'asphalte plane, 5 modules",
        "PICTURE_LI": null,
        "PICTURE_DE": null,
        "PICTURE__1": null
      },
      "geometry": {
        type: "Point",
        "coordinates": [-75.3372987731628, 45.383321536272049]
      }
    },
    {
      type: "Feature",
      "properties": {
        "DIS_ID": 1219,
        "FACILITYID": 28001,
        "NAME": "Bob MacQuarrie Skateboard Park (SK8 Extreme Park)",
        "NAME_FR": "Planchodrome Bob-MacQuarrie (Parc SK8 Extreme)",
        "ADDRESS": "1490 Youville Drive",
        "ADDRESS_FR": "1490, promenade Youville",
        "FACILITY_T": "other",
        "FACILITY_1": "autre",
        "ACCESSCTRL": "no/non",
        "ACCESSIBLE": "no/non",
        "OPEN": null,
        "NOTES": "Outdoor",
        "MODIFIED_D": "2018/01/18",
        "CREATED_DA": null,
        "FACILITY": "Community: mid size facility to service population of 40,000 plus",
        "FACILITY_F": "Communautaire : installation de taille moyenne assurant des services à 40 000 résidents ou plus.",
        "DESCRIPTIO": "Flat asphalt surface, 10 components, City run learn to skateboard programs, City run skateboard camps in summer",
        "DESCRIPT_1": "Surface d'asphalte plane, 10 modules, programmes et camps de planche à roulettes en été géré par la Ville",
        "PICTURE_LI": null,
        "PICTURE_DE": null,
        "PICTURE__1": null
      },
      "geometry": {
        type: "Point",
        "coordinates": [-75.546518086577947, 45.467134581917357]
      }
    },
    {
      type: "Feature",
      "properties": {
        "DIS_ID": 1157,
        "FACILITYID": 28002,
        "NAME": "Walter Baker Skateboard Park",
        "NAME_FR": "Planchodrome Walter-Baker",
        "ADDRESS": "100 Charlie Rogers Place",
        "ADDRESS_FR": "100, place Charlie Rogers",
        "FACILITY_T": "bowl",
        "FACILITY_1": "bol",
        "ACCESSCTRL": "no/non",
        "ACCESSIBLE": "no/non",
        "OPEN": null,
        "NOTES": "Outdoor",
        "MODIFIED_D": "2018/01/18",
        "CREATED_DA": null,
        "FACILITY": "Community : mid size facility to service population of 40,000 plus",
        "FACILITY_F": "Communautaire : installation de taille moyenne assurant des services à 40 000 résidents ou plus.",
        "DESCRIPTIO": "Concrete bowl, 7,000 sq ft",
        "DESCRIPT_1": "Bol de béton, 7 000 pi2",
        "PICTURE_LI": null,
        "PICTURE_DE": null,
        "PICTURE__1": null
      },
      "geometry": {
        type: "Point",
        "coordinates": [-75.898610599532319, 45.295014379864874]
      }
    },
    {
      type: "Feature",
      "properties": {
        "DIS_ID": 9,
        "FACILITYID": 28006,
        "NAME": "Roving Skateboard Park Location",
        "NAME_FR": "Lieu de planchodrome itinérant",
        "ADDRESS": "2785 8th Line Road",
        "ADDRESS_FR": "2785, chemin 8th Line",
        "FACILITY_T": "other",
        "FACILITY_1": "autre",
        "ACCESSCTRL": "no/non",
        "ACCESSIBLE": "no/non",
        "OPEN": null,
        "NOTES": "Outdoor - Mobile",
        "MODIFIED_D": "2018/01/18",
        "CREATED_DA": null,
        "FACILITY": "Metcalfe Community Centre - Roving Skateboard Park Location",
        "FACILITY_F": "Centre communautaire de Metcalfe - Lieu de planchodrome itinérant",
        "DESCRIPTIO": "Flat surface, 5 components",
        "DESCRIPT_1": "Surface plane, 5 modules",
        "PICTURE_LI": null,
        "PICTURE_DE": null,
        "PICTURE__1": null
      },
      "geometry": {
        type: "Point",
        "coordinates": [-75.468561642270757, 45.23032561834377]
      }
    },
    {
      type: "Feature",
      "properties": {
        "DIS_ID": 1160,
        "FACILITYID": 28007,
        "NAME": "Roving Skateboard Park Location",
        "NAME_FR": "Lieu de planchodrome itinérant",
        "ADDRESS": "10 Warner Colpitts Lane",
        "ADDRESS_FR": "10, ruelle Warner Colpitts",
        "FACILITY_T": "flat",
        "FACILITY_1": "plat",
        "ACCESSCTRL": "yes/oui",
        "ACCESSIBLE": "no/non",
        "OPEN": null,
        "NOTES": "Indoor - Summer",
        "MODIFIED_D": "2018/03/07",
        "CREATED_DA": null,
        "FACILITY": "Johnny Leroux Stittsville Community Arena - Roving Skateboard Park Location",
        "FACILITY_F": "Aréna communautaire de Stittsville Johnny-Leroux - Lieu de planchodrome itinérant",
        "DESCRIPTIO": "Flat surface, 5 components",
        "DESCRIPT_1": "Surface plane, 5 modules",
        "PICTURE_LI": null,
        "PICTURE_DE": null,
        "PICTURE__1": null
      },
      "geometry": {
        type: "Point",
        "coordinates": [-75.926651366520872, 45.260659774950561]
      }
    },
    {
      type: "Feature",
      "properties": {
        "DIS_ID": 1693,
        "FACILITYID": 28016,
        "NAME": "Legacy Skateboard Park",
        "NAME_FR": "Planchodrome Legacy",
        "ADDRESS": "101 Centrepointe Drive",
        "ADDRESS_FR": "101, promenade Centrepointe",
        "FACILITY_T": "bowl",
        "FACILITY_1": "bol",
        "ACCESSCTRL": "no/non",
        "ACCESSIBLE": "no/non",
        "OPEN": null,
        "NOTES": "Outdoor",
        "MODIFIED_D": "2018/01/18",
        "CREATED_DA": null,
        "FACILITY": "District: larger facility to service population of 100,000 plus",
        "FACILITY_F": "De district : grande installation assurant des services à 100 000 résidents ou plus.",
        "DESCRIPTIO": "Large concrete bowl, many street and vertical components, 17,000 sq ft",
        "DESCRIPT_1": "Grand bol de béton, modules de rue et modules verticaux, 17 000 pi2",
        "PICTURE_LI": null,
        "PICTURE_DE": null,
        "PICTURE__1": null
      },
      "geometry": {
        type: "Point",
        "coordinates": [-75.760933332842754, 45.345566668964558]
      }
    },]
}];

