import React from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  Edit,
  Inject,
} from '@syncfusion/ej2-react-grids';
import { zoonoticData, diseasesGrid, DiseasesXAxis, DiseasesYAxis, LinePrimaryXAxis } from '../data/dummy';
import { Header, Button, Navbar, LineChart } from '../components';
import { Link } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { 
  Category, 
  ChartComponent, 
  LineSeries, 
  SeriesCollectionDirective, 
  SeriesDirective,
  Legend,
  DataLabel,
  ColumnSeries
 } from '@syncfusion/ej2-react-charts';
import DiseaseLineChart from '../components/Charts/DiseaseLineChart';

const Zoonotic = () => { 
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();
/*
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);
  */
  const sdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-project-0-oudwg", // ~REPLACE~ with the Base URL from your Embed Chart dialog.
  });
  const chart = sdk.createChart({
    chartId: "63072e21-7c89-4270-868c-3d2954a6cc1f", // ~REPLACE~ with the Chart ID from your Embed Chart dialog.
    height: "700px",
    // Additional options go here
  });
  async function renderChart(){
    chart.render(document.getElementById("chart"));
    addEventListeners();
  }

  function addEventListeners() {
    /* Refresh button */
    document
      .getElementById("refresh-button")
      .addEventListener("click", async function () {
        await chart.refresh();
      });
    }

  const filterSettings = { type: 'Excel' };
  return (
    
    <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
    <div className='fixed right-4 bottom-4' style={{ zIndex: '1000' }}>
      <TooltipComponent content='Settings' position='Top'>
        <button
          type='button'
          onClick={() => setThemeSettings(true)}
          style={{ background: currentColor, borderRadius: '50%' }}
          className='text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray'
        >
          <FiSettings />
        </button>
      </TooltipComponent>
    </div>
    <div className='fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full '>
    <Navbar />
      </div>
      <Header category='Disease' title='Zoonotic Diseases' />
      <div className='mb-2'>
        <Link to ='/zoonotic/anthraxes'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='Anthrax'
          bgHoverColor='gray-500'
        />
        </Link>
        <Link to ='/zoonotic/bovinetuberculoses'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='Bovine Tuberculosis'
          bgHoverColor='gray-500'
        />
        </Link>
        <Link to ='/zoonotic/brucelloses'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='Brucellosis'
          bgHoverColor='gray-500'
        />
        </Link>
        <Link to ='/zoonotic/rabies'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='Rabies'
          bgHoverColor='gray-500'
        />
        </Link>
        <Link to ='/zoonotic/dogbites'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='Dog Bites'
          bgHoverColor='gray-500'
        />
        </Link>
        <Link to ='/zoonotic/salmonelloses'>
        <Button
          className='bg-white text-gray-500 hover:text-white'
          text='Salmonellosis'
          bgHoverColor='gray-500'
        />
        </Link>
      </div>

      <GridComponent
        id='gridcomp'
        dataSource={zoonoticData}
        allowFiltering={true}
        filterSettings={filterSettings}
        toolbar={['Search']}
        width='auto'
      >
        <ColumnsDirective>
          {diseasesGrid.map((item, index) => (
            /* eslint-disable react/jsx-props-no-spreading */
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Search,
            Toolbar,
            Resize,
            ContextMenu,
            Filter,
            Page,
          ]}
        />
      </GridComponent>
      
      <div className='container text-2xl mt-8'>
        <ChartComponent
        title='Zoonotic Diseases'
        width='600px'
        primaryXAxis={{valueType:"Category", title:"Disease"}}
        primaryYAxis={{title:"Total"}} legendSettings={{visible:true}}
        >
          <Inject services={[ColumnSeries, LineSeries, Category, Legend, DataLabel]}/>
          <SeriesCollectionDirective>
            <SeriesDirective
            type='Column'
            dataSource={zoonoticData}
            xName='Disease'
            yName='Total'
            name='Diseases'
            marker={{dataLabel:{visible:true}, visible:true}}>

            </SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
        
    </div>
  );
};

export default Zoonotic;
