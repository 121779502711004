import React, {Component} from 'react'



export default class UserList extends Component {
    super(props){
        this.state = {
            
        }
    }

  render() {
    return (
        <div>
        <p>UserList</p>
        </div>
    )
  }
} 