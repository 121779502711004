import React from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip } from '@syncfusion/ej2-react-charts';

import { linePrioritySeries, LinePriorityXAxis, LinePriorityYAxis } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';

const LinePriorityChart = () => {
  const { currentMode } = useStateContext();

  return (
    <ChartComponent
      id="line-pchart"
      height="300px"
      primaryXAxis={LinePriorityXAxis}
      primaryYAxis={LinePriorityYAxis}
      chartArea={{ border: { width: 0 } }}
      tooltip={{ enable: true }}
      background={currentMode === 'Dark' ? '#33373E' : '#fff'}
      legendSettings={{ background: 'white' }}
    >
      <Inject services={[LineSeries, DateTime, Legend, Tooltip]} />
      <SeriesCollectionDirective>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {linePrioritySeries.map((item, index) => <SeriesDirective key={index} {...item} />)}
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};

export default LinePriorityChart;
