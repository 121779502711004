import React, {Component} from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Search,
  ContextMenu,
  Filter,
  Page,
  Edit,
  Inject,
} from '@syncfusion/ej2-react-grids';
import { zoonoticData, entriesGrid, dogBiteData } from '../../data/dummy';
import bovinetuberculosisData from '../../data/bovinetuberculosis.json';
import { Header, Button } from '../../components';
import axios from 'axios';
import { Category, ChartComponent, ColumnSeries, DataLabel, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import { Tooltip } from '@syncfusion/ej2-react-popups';
import Maps from '../../components/Maps';
import MapBovine from '../../components/Map/MapBovineTuberculosis';

const BovineTuberculosis = props => (
  <tr>
    <td>{props.bovinetuberculosis.username}</td>
    <td>{props.bovinetuberculosis.description}</td>
    <td>{props.bovinetuberculosis.duration}</td>
    <td>{props.bovinetuberculosis.date.substring(0,10)}</td>
  </tr>
)

export default class BovineTuberculoses extends Component{
  constructor(props)
  {
    super(props);
    this.state = {bovinetuberculoses :[]};
  }
  
  Tabs = ({ color }) => {
    const [openTab, setOpenTab] = React.useState(1);
    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full">
            <ul
              className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Data Table
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                   Charts
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 3
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                   Map
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  {/*
                  Tab 1
                  */}
                    <GridComponent 
                    id='bovinetuberculosis'
                    dataSource={bovinetuberculosisData}
                    /*dataSource={this.state.bovinetuberculosis}*/
                    allowFiltering={true}
                    allowPaging={true}
                    filterSettings={this.filterSettings}
                    width='auto'>
                      <ColumnsDirective>
                        {entriesGrid.map((item, index) => (
                        /* eslint-disable react/jsx-props-no-spreading */
                        <ColumnDirective key={index} {... item}/>
                      ))}
                      <Inject
                        services={[
                        Filter,
                        Page,
                        Search,
                      ]}
                    />
                      
                      </ColumnsDirective>
                    </GridComponent>
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                    {/*
                    Tab 2
                    */}
                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=634f7496-847c-42f6-80fb-75906382b624&maxDataAge=1800&theme=light&autoRefresh=true"></iframe>
                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=634f7514-0416-4554-8ad5-ac6ce7d6a3c6&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=634f7611-d3ad-4b5d-8208-cf034adb92da&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                  </div>
                  <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                    {/**
                     * Tab 3
                     */}
                     <iframe width="1024" height="800" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=f9d30504-4dfc-40a9-8a3b-c18c5e030eae&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                     {/* <Maps/> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  componentDidMount(){
    axios.get('https://api.pakonehealth.org/bovinetuberculoses')
    .then(response => {
      this.setState({bovinetuberculosis: response.data});
      console.log("received data");

    })
    .catch((error) => {
      console.log(error);
      console.log("Not receiving data");
    })
  }

  entriesList(){
    return this.state.bovinetuberculosis.map(currentEntry => {
      return <BovineTuberculosis bovinetuberculosis={currentEntry} />;
    })
  }

  filterSettings = { type: 'Excel' };
  fields = { text: 'text', value: 'value' };
  format = { type: 'datetime', format: 'M/d/y hh:mm a' };
  render() {
    return (    
    <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
    <Header category='Zoonotic Diseases' title='Bovine Tuberculosis' />
      <this.Tabs color={'teal'}/>

        </div>
    )
  }

}
