import React , {Component} from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  Edit,
  Inject,
} from '@syncfusion/ej2-react-grids';
import { zoonoticData, DHIS2Grid, DiseasesXAxis, DiseasesYAxis, LinePrimaryXAxis } from '../../data/dummy';
import { Header, Button, Navbar, LineChart } from '../../components';
import { Link } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { 
  Category, 
  ChartComponent, 
  LineSeries, 
  SeriesCollectionDirective, 
  SeriesDirective,
  Legend,
  DataLabel,
  ColumnSeries
 } from '@syncfusion/ej2-react-charts';
import DiseaseLineChart from '../..//components/Charts/DiseaseLineChart';
import axios from 'axios';
import Base64 from 'base-64';
import { dhis2jul, dhis2nov, dhis2oct } from '.'
import { dogBiteData, entriesGrid } from '../../data/dummy';
import dogBitesData from '../../data/dogbites 22.json';

export default class July extends Component{

  render() {
    const filterSettings = { type: 'Excel' };
    return (    
    <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
    <Header category='DHIS - 2' title='DHIS - 2 (July)' />

    <GridComponent
        id='gridcomp'
        dataSource={dhis2jul}
        filterSettings={filterSettings}
        toolbar={['Search']}
        width='auto'
      >
      <ColumnsDirective>
        {DHIS2Grid.map((item, index) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <ColumnDirective key={index} {...item} />
        ))}
      </ColumnsDirective>
      <Inject
        services={[
          Search,
          Toolbar,
          Resize,
          ContextMenu,
          Page,
        ]}
      />
    </GridComponent>
    </div>
    )
  }
}