import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('@32302e342e30GXMujfROl9TvpiWuFWVBKonveYHBbmZDJoG Splh3R4=;Mgo DSMBaFt/QHRqVVhjVFpGaV5dX2NLfUN1T2NddV5yZCQ7a15RRnVfQ19mSX1TckVhWHZccA==;Mgo DSMBMAY9C3t2VVhkQlFadVdJX3xIYVF2R2BJfFRwcF9GYkwgOX1dQl9gSX9Rc0VgWXtecnVcQmA=;Mgo DSMBPh8sVXJ0S0J XE9HflRAQmJWfFN0RnNadV50flVEcC0sT3RfQF5jSHxWdkZjXX9ccX1QRg==;@32302e342e30O90r6OGDPxFt5twXi9dnWrZS/RiBIyaBpUZ5WfsVq/A=;NRAiBiAaIQQuGjN/V0Z WE9EaFxKVmFWf0x0RWFab116dFFMYF5BNQtUQF1hSn5SdEBjWn9acHZUT2lc;NT8mJyc2IWhhY31nfWN9YGtoYXxifGFjYWBzYGljZ2ljYXMSHmgjPDsyPDU1OjA6Mj8TND4yOj99MDw ;ORg4AjUWIQA/Gnt2VVhkQlFadVdJX3xIYVF2R2BJfFRwcF9GYkwgOX1dQl9gSX9Rc0VgWXtecnZVRGA=;@32302e342e30adIDep4/xjjwNxlu0fUNPqGf/QWG4kF7BegT79AmU6s=;@32302e342e30G/MClfJdF6drQvfvV9GDbm LHXXy1Q7d ISEww8wGLM=;@32302e342e30GYOgZELdWGLwHZFZ0QJRPIWrJatfyzg7O4LZ8O4cuOE=;@32302e342e30V0ueHdYpo4mV5npzdopdETRlVtM 4WiyVHC7Sd7OKas=;@32302e342e30GXMujfROl9TvpiWuFWVBKonveYHBbmZDJoG Splh3R4= ');

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <App />
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
